.container {
  padding-top: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  position: relative;
  background-color: #fff;
}

.container h4 {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: 400;
  padding-bottom: 4rem;
}

.bg {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 60%;
  background-color: var(--images-bg);
  background-color: #fff;
  z-index: 0;
}

.main-image {
  height: 70rem;
  z-index: 5;
}

.main-img {
  height: 100%;
  width: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.images-container__overflow {
  height: 100%;
  list-style: none;
  display: flex;
  gap: 2rem;
  overflow: hidden;
  overflow-x: auto;
  padding: 0 3rem;
}

.images-container {
  width: 104rem;
  /* width: 100%; */
  height: 14rem;
  position: relative;
}

.images-container:first-child {
  margin-left: 4rem;
}

.left-fade {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3rem;
  background: linear-gradient(
    90deg,
    #fff 0%,
    #fff 49%,
    /* var(--images-bg) 0%,
    var(--images-bg) 49%, */ rgba(246, 246, 246, 0)
      100%
  );
}

.right-fade {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 3rem;
  background: linear-gradient(
    90deg,
    rgba(246, 246, 246, 0) 0%,
    #fff 49%,
    #fff 100% /* var(--images-bg) 49%,
    var(--images-bg) 100% */
  );
}

.images-container__overflow::-webkit-scrollbar {
  display: block;
  left: 0;
  /* width: 0.2rem; */
  height: 1rem;
}

.images-container__overflow::-webkit-scrollbar-track {
  background: rgba(175, 175, 175, 0.034);
}

.images-container__overflow::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb);
  border-radius: 2rem;
  border: 0.2rem transparent rgba(175, 175, 175, 0);
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .images-container {
    width: 100%;
    margin: 0 2rem;
  }
  .main-image {
    height: 60rem;
  }
  .images-container {
    height: 10rem;
  }
}

/* 900 */
@media (max-width: 56.25em) {
  .main-image {
    height: 50rem;
  }
}

/* 800 */
@media (max-width: 50em) {
  .main-image {
    height: 40rem;
  }
  .container {
    display: none;
  }
}

/* 600 */
@media (max-width: 37.5em) {
  .images-container__overflow::-webkit-scrollbar {
    height: 0.6rem;
  }
  .left-fade {
    display: none;
  }
  .right-fade {
    display: none;
  }
  .images-container__overflow {
    padding: 0 1rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .images-container {
    height: 8rem;
  }
  .images-container__overflow {
    gap: 0.6rem;
  }
  .main-image {
    height: 30rem;
  }
}

/* 340 */
@media (max-width: 21.25em) {
  .main-image {
    height: 20rem;
  }
}
