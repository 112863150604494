.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* gap: 0.8rem; */
}

.input label {
  text-transform: uppercase;
  font-size: 1.4rem;
  color: var(--text-color);
  font-weight: 500;
  margin-bottom: 0.6rem;
}

.input input {
  width: 100%;
  border: none;
  border-left: 0.4rem solid var(--background);
  background-color: var(--background);
  border-radius: 0.4rem;
  color: var(--text-color);
  font-size: 1.6rem;
  height: 4rem;
  padding: 0 2rem;
  transition: all 0.5s ease;
}

.input input:focus {
  outline: none;
  border-color: var(--main-color);
}

.input input::placeholder {
  color: var(--accent-secondary-color);
}

.error {
  color: red;
  height: 1.6rem;
  font-size: 1.4rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .input label {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0.4rem;
  }

  .input input {
    font-size: 1.4rem;
    /* height: 3.2rem; */
  }
}

@media (max-width: 50em) {
  .input input {
    padding: 0 1rem;
  }
}
