/*  */

.container {
  padding-top: 0rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin: 0 0 8rem 0;
}

.divider {
  height: 4rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    /* padding-top: 1rem; */
    gap: 1rem;
  }
}

/* 600px */
@media (max-width: 37.5em) {
  .container {
    padding-top: 1rem;
  }
}
