.container {
  display: none;
  height: 6rem;
  width: 100%;
  padding: 0 2rem;
  align-items: center;
  justify-content: space-between;
  /* flex-direction: column; */
  /* position: fixed; */
  top: 0;
  background-color: #fff;
  z-index: 900;
}

.navs {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.opacity {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 6rem);
  backdrop-filter: blur(1rem);
  z-index: 400;
  background-color: #00000081;
  left: 0;
  bottom: 0;
}

.menu-button {
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.msg {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  height: 1rem;
  width: 1rem;
  border-radius: 2rem;
  background-color: red;
  border: 0.2rem solid #fff;
}

.icon {
  font-size: 2.4rem;
  color: var(--text-color);
}

.nav__links {
  transform: translate(0%, 0%);
  padding: 1rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  position: fixed;
  right: 0;
  top: 0rem;
  height: calc(100vh);
  width: 30rem;
  background-color: #fff;
  background-color: var(--background);
  z-index: 950;
  animation: from-right 0.3s ease-in;
}

.close-box {
  margin-bottom: 2rem;
}

.single__nav {
  font-size: 1.6rem;
  color: var(--text-color);
  /* text-transform: uppercase; */
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 0;
}

.single__nav-last {
  border-top: 0.1rem solid var(--accent-secondary-color);
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.languages {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.dropdown {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 2rem;
  background-color: var(--secondary-color);
  background-color: #fff;
}

.single__dropdown {
  font-size: 1.6rem;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 0;
}

.logout {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-icons {
  font-size: 1.6rem;
}

.logout-icon {
  font-size: 2rem;
}

@keyframes from-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    display: flex;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .nav__links {
    width: 100%;
  }
  .single__nav {
    font-size: 1.6rem;
  }
  .single__dropdown {
    font-size: 1.6rem;
  }
  .logout-icon {
    font-size: 2rem;
  }
}

/* 400px */
@media (max-width: 25em) {
}
