.container {
  width: 50rem;
  background-color: var(--main-color);
  /* height: calc(100% - 8rem); */
  height: 90%;
  max-height: 55rem;
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
}

.container h4 {
  font-size: 1.8rem;
  font-weight: 300;
}

.container h3 {
  font-size: 4.6rem;
  font-weight: 300;
  margin-bottom: 2rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    width: 40rem;
  }
  .container h3 {
    font-size: 2.8rem;
  }
  .container h4 {
    font-size: 1.6rem;
  }
}

/* 900 */
@media (max-width: 68.75em) {
  .container {
    max-height: 45rem;
    width: 25rem;
    padding-left: 2rem;
  }
  .container h3 {
    font-size: 2.4rem;
  }
  .container h4 {
    font-size: 1.4rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    width: 20rem;
    max-height: 47rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .container {
    width: 100%;
    min-height: 11rem;
    height: 10%;
    gap: 0.2rem;
  }
  .container h3 {
    font-size: 2rem;
    margin-bottom: 0.4rem;
  }
}
