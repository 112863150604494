.end-chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.end-chat p {
  font-size: 1.8rem;
  align-self: center;
}

.end-chat h4 {
  font-size: 1.8rem;
  text-transform: uppercase;
  color: var(--text1);
  padding-left: 2rem;
  border-left: 0.4rem solid var(--main-color);
}

.end-chat__btns {
  margin-top: 2rem;
  display: flex;
  gap: 0.6rem;
}

.end-chat__btns p {
  flex: 1;
  display: flex;
  padding: 1rem 0;
  border-radius: 0.4rem;
  font-size: 1.6rem !important;
  color: #fff;
  align-items: center;
  justify-content: center;
  background-color: var(--button);
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
}

.end-chat__btns p:hover {
  background-color: var(--button-hover);
}
