/*  */

.card {
  max-width: 30rem;
  width: 100%; /* Keeps the card's width managed by the grid */
  height: 25rem; /* Adjusted from 30rem to make the card shorter */
  display: flex;
  flex-direction: column;
  border-radius: 0.2rem;
  /* border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem; */
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for depth */
  background-color: #fff;
}

.image-container {
  width: 100%;
  height: 60%; /* 60% of the card's height */
  /* border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: antiquewhite;
  overflow: hidden;
  position: relative;
}

.images-count {
  position: absolute;
  bottom: 0.4rem;
  left: 0.4rem;
  background-color: var(--secondary-color);
  background-color: var(--main-color);
  background-color: #020202b7;
  color: var(--text-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  padding: 0.2rem 0.4rem;
  border-radius: 0.2rem;
}

.icon {
  font-size: 1.4rem;
}

.images-count p {
  font-size: 1rem;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container and is zoomed in */
  transition: all 0.3s ease;
}

.image:hover {
  transform: scale(1.04);
}

.promoted {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--promoted);
  color: #fff;
  padding: 0.4rem 1rem 0.4rem 2rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  font-weight: 700;
  clip-path: polygon(100% 100%, 100% 0%, 0% 0%, 10% 100%);
}

.details {
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0.4rem;
}

.locations-and-date {
  margin: 0.2rem 0.4rem 0.4rem 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
}

.location {
  /* border: 0.1rem solid var(--accent-secondary-color);
  padding: 0.2rem 1rem;
  border-radius: 0.2rem; */
  font-size: 1.2rem;
}

.date {
  /* border: 0.1rem solid var(--accent-secondary-color);
  padding: 0.2rem 1rem;
  border-radius: 0.2rem; */
  font-size: 1.2rem;
}

.title-and-price {
  color: var(--text-color);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 1.5rem; /* Adjusted from 2rem to 1.5rem */
  line-height: 1.6rem;
  max-height: 3.2rem;
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Ensures the link uses the current text color */
  display: -webkit-box; /* Enables the use of line clamping */
  -webkit-line-clamp: 3; /* Limit text to 3 lines */
  -webkit-box-orient: vertical; /* Sets the direction of the layout for the text */
  overflow: hidden; /* Hides text that overflows the set limit */
  text-overflow: ellipsis; /* Adds an ellipsis to text that overflows */
  margin: auto 0; /* Centers the title vertically within its container */
  width: 100%; /* Ensures the title takes up all horizontal space within its container */
}

.title {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.price {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--text-color);
}

.user {
  font-size: 1.4rem;
}

/* MEDIA QUERIES */
/* 450 */
@media (max-width: 28.13em) {
  .card {
    max-width: 40rem;
    height: 20rem;
  }
  .details {
    height: 7.8rem;
  }
  .locations-and-date {
    margin: 0.2rem 0.2rem 0.2rem 0.2rem;
  }
  .location {
    font-size: 1rem;
  }
  .date {
    font-size: 1rem;
  }
  .title {
    font-size: 1.4rem;
    line-height: 1.5rem;
    max-height: 3rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .price {
    font-size: 1.4rem;
  }
}
