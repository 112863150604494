.container {
  width: 100%;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  row-gap: 2rem;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    /* margin-top: 2rem; */
    max-width: 104rem;
    width: 100%;
    row-gap: 2rem;
    column-gap: 1rem;
    padding: 0rem 2rem;
  }
}

/* 1000 */
@media (max-width: 62.5em) {
}

/* 850 */
@media (max-width: 53.13em) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  }
}

/* 750 */
@media (max-width: 46.88em) {
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    padding: 0 1rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .container {
    margin-bottom: 0rem;
  }
}

/* 450 */
@media (max-width: 28.13em) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  }
}
