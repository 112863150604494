.container {
  /* margin-top: 1rem; */
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  align-items: flex-start;
  font-size: 1.8rem;
  color: #fff;
  color: var(--text-color);
  text-transform: uppercase;
  margin-top: 0rem;
}

.chart p {
  text-transform: none;
  font-weight: 500;
}

.details {
  flex: 1;
  height: 100%;
}

.details h4 {
  width: 100%;
  font-size: 2rem;
  text-transform: uppercase;
  color: #fff;
  color: var(--text-color);
  margin-bottom: 3.2rem;
}

.detail {
  font-size: 1.6rem;
  color: #fff;
  color: var(--text-color);
  width: 100%;
  display: flex;
  gap: 1.4rem;
  align-items: center;
  margin-bottom: 2rem;
}

.details span {
  height: 100%;
  /* background-color: #f6f6f6; */
  border-right: 0.1rem solid #d4d4d4;
  border-right: 0.1rem solid var(--input-separator);
  padding: 0.4rem 0 0.4rem 1rem;
  /* border-radius: 0.4rem; */
  width: 16rem;
  text-align: left;
}

.credit {
  text-transform: uppercase;
  font-weight: 500;
  height: 4rem;
  background-color: var(--background);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 1.4rem;
  color: var(--text-color);
  padding: 0 1rem;
  border-radius: 0.2rem;
}

.btn {
  background-color: var(--main-color);
  letter-spacing: 0.04rem;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin: 0 0 0 auto;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn:hover {
  background-color: var(--main-color-hover);
}

/* MEDIA QUERIES */
/* 900 */
@media (max-width: 56.25em) {
  .detail {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  /* .credit {
    margin-bottom: ;
  } */
}

/* 650 */
@media (max-width: 40.63em) {
  .container {
    width: 100%;
  }
  .details {
    width: 100%;
  }
}
