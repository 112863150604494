.container {
  width: 100%;
  height: 100%;
  max-width: 14rem;
  max-height: 14rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.4rem;
  align-self: center;
  justify-self: center;
  background-color: var(--background);
  border: 1rem solid #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.container:hover {
  background-color: #ebebeb;
}

.container label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.images-input {
  display: none;
}

.icon {
  font-size: 10rem;
  color: #fff;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    height: 10rem;
    width: 10rem;
    border-width: 0.6rem;
  }
  .icon {
    font-size: 5rem;
  }
}
