.container {
  width: 100%;
  height: calc(100vh - 8rem);
  height: calc((var(--vh, 1vh) * 100) - 8rem);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  padding: 8rem 0;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    height: calc(100vh - 6rem);
    height: calc((var(--vh, 1vh) * 100) - 6rem);
  }
  /*
  .container h2 {
    font-size: 2.8rem;
  }
  .form {
    width: 90%;
    gap: 1rem;
  }
  .form input {
    font-size: 1.4rem;
    height: 4rem;
  } */
}

/* 650 */
@media (max-width: 40.63em) {
  .container {
    padding: 1rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .container {
    flex-direction: column;
  }
}
