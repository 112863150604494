.container {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  border-radius: 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  background-color: #ffffff;
  overflow: hidden;
}

.container p {
  font-size: 1.6rem;
}

.header {
  padding: 0.4rem 0;
  margin-bottom: 0.6rem;
  width: 100%;
  color: var(--text-color) !important;
  /* color: #fff !important; */
  /* background-color: #f0f0f0; */
}

.title {
  text-align: left;
  color: var(--text-color) !important;
  margin: 0.6rem 2rem 1rem 2rem;
  font-size: 1.8rem !important;
  font-weight: 500;
}

.reason {
  text-align: left;
  margin: 0rem 1rem 0rem 2rem;
  font-size: 1.6rem !important;
  color: var(--text-color) !important;
}

.description {
  text-align: left;
  margin: 0rem 1rem 0rem 2rem;
  font-size: 1.6rem !important;
  color: var(--text-color) !important;
}

.icon {
  font-size: 3.2rem;
  margin: 0.4rem auto 0.6rem auto;
  color: var(--accent-color);
}

.buttons {
  width: 100%;
  display: flex;
  gap: 0.2rem;
  margin-top: 1rem;
}

.buttons button {
  width: 50%;
  background: none;
  border: none;
  font-size: 1.6rem;
  background-color: var(--main-color);
  color: #fff;
  padding: 0.8rem 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.block {
  /* background-color: var(--report-blocked) !important; */
}
.reject {
  /* background-color: var(--report-rejected) !important; */
}

.block:hover {
  background-color: var(--report-blocked-hover) !important;
}
.reject:hover {
  background-color: var(--report-rejected-hover) !important;
}

.report-details {
  /* width: 100%; */
  flex: 1;
  display: flex;
  /* gap: 1rem; */
  margin: 0.4rem 2rem;
  /* border: 0.1rem solid var(--border2); */
  background-color: var(--background);
  border-radius: 0.4rem;
  padding-right: 1rem;
  overflow: hidden;
}

.report-details__specs {
  margin-left: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.report-details__specs p {
  font-size: 1.6rem !important;
  text-align: left;
}

.report-details img {
  width: 8rem;
  height: auto;
}

.report-details__title {
  height: 100%;
}

.report-details__visit-link {
  align-self: flex-end;
  font-size: 1.4rem;
  color: var(--text1);
}

.report-details__show-chat {
  width: 20rem;
  background-color: var(--text-color);
  color: #fff;
  font-size: 1.6rem;
  border-radius: 0.4rem;
  padding: 0.4rem 0;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.report-details__show-chat:hover {
  background-color: var(--text2);
}

.status {
  font-size: 1.8rem;
  font-weight: 400;
  padding: 0.4rem 0;
  margin-top: 1rem;
  color: #fff;
  text-transform: uppercase;
}

/* MEDIA QUERIES */
/* 900 */
@media (max-width: 56.25em) {
  .title {
    font-weight: 400;

    margin: 0rem 1rem 0.6rem 1rem;
  }
  .report-details__specs p {
    font-size: 1.4rem !important;
  }
  .report-details {
    margin: 0.4rem 1rem;
  }
  .reason {
    margin: 0rem 1rem 0rem 1rem;
  }
}

/* 750 */
@media (max-width: 46.88em) {
  .title {
    font-size: 1.6rem !important;
  }
  .status {
    font-size: 1.6rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .reason {
    font-size: 1.4rem !important;
  }
  .icon {
    font-size: 2.4rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .title {
    font-size: 1.4rem !important;
  }
  .report-details p {
    font-size: 1.2rem !important;
  }
}
