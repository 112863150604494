.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items to the top of the container */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.4rem;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
  position: relative;
  animation: 0.5s fadeIn ease;
}

.header {
  display: flex;
  align-items: center;
  justify-self: center;
  width: 100%;
  height: 8rem;
  padding: 1rem 0rem;
  color: var(--text-color);
  font-size: 1.8rem;
  margin-bottom: 1rem;
  background-color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
}

.header:hover {
  background-color: #f6f6f6;
}

.header h3 {
  font-weight: 400;
  padding: 2rem 2rem;
}

.container img {
  width: 14rem;
  height: 14rem;
  /* border-radius: 4px; */
  object-fit: cover;
}

.body {
  display: flex;
  width: 100%;
  padding: 0rem 1rem;
  gap: 1rem;
  height: 150px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4rem;
  width: 100%;
}

.single-info {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1.4rem;
  color: var(--text-color);
  border-radius: 0.4rem;
  padding: 0.4rem 2rem;
  background-color: var(--background);
  width: 100%;
}

.description {
  width: 100%;
  display: flex;
  gap: 0.4rem;
  flex-direction: column;
  color: var(--text-color);
  align-items: flex-start;
  padding: 0 2rem;
  margin-bottom: 2rem;
  margin-top: 0rem;
  /* height: 10rem; */
}

.description h4 {
  font-size: 1.6rem;
  font-weight: 600;
  /* background-color: var(--background); */
  padding: 0rem 0rem;
  /* border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem; */
  margin-bottom: 0rem;
  border-radius: 0.4rem;
}

.description p {
  font-size: 1.6rem;
  color: var(--text-color);
  text-align: justify;
}

.articleImage {
  width: 100px; /* Fixed width for the image */
  height: 100px; /* Fixed height for the image */
  border-radius: 4px;
  object-fit: cover; /* Keep the aspect ratio of the image */
}

.footer {
  margin: auto 0 0 0;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  background-color: #f6f6f6;
  background-color: var(--main-color);
  position: relative;
}

.edit {
  background-color: var(--button);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  border-radius: 0.2rem;
  font-size: 2.4rem;
  color: #fff;
  z-index: 10;
  cursor: pointer;
  transition: all 0.5s ease;
}

.edit:hover {
  background-color: var(--button-hover);
}

.actions {
  /* position: absolute; */
  display: flex;
  align-items: center;
  gap: 0.2rem;
  left: 0;
  width: 100%;
  height: 100%;
}

.rectangularArea {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  background-color: var(--main-color);
  color: #fff;
  transition: all 0.5s ease;
  cursor: pointer;
}

.rectangularArea:hover {
  background-color: var(--button-hover);
  background-color: var(--main-color-hover);
  color: #fff;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .header {
    height: 6rem;
    font-size: 1.6rem;
  }
  .body {
    padding: 0rem 1rem;
    height: 14rem;
  }
  .container img {
    width: 12rem;
    height: 12rem;
  }
  .description {
    padding: 0rem 1rem;
    margin-bottom: 1rem;
  }
  .description h4 {
    font-size: 1.4rem;
    margin-bottom: 0;
  }
  .description p {
    font-size: 1.4rem;
  }
}

/* 1000 */
@media (max-width: 62.5em) {
}

/* 750 */
@media (max-width: 46.88em) {
}

/* 550 */
@media (max-width: 34.38em) {
}

/* 450 */
@media (max-width: 28.13em) {
  .container {
    box-shadow: none;
  }
  .header {
    font-size: 1.4rem;
  }
  .single-info {
    font-size: 1.2rem;
    padding: 0.4rem 1rem;
  }
  .footer {
    height: 8rem;
  }
  .actions {
    display: grid;
    background-color: #fff;
    grid-template-columns: repeat(2, 1fr);
  }
  .rectangularArea {
    border-radius: 0.2rem;
  }
}

/* 350 */
@media (max-width: 21.88em) {
  .header {
    height: 5rem;
    margin-bottom: 1rem;
  }
  .body {
    height: 13rem;
  }
}
