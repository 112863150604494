.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  justify-content: center;
  row-gap: 1rem;
  column-gap: 2rem;
  box-shadow: rgba(65, 65, 65, 0.199) -4px 9px 25px -6px;
  padding: 6rem 2rem 2rem 2rem;
  border-radius: 1rem;
  background-color: #fff;
  position: relative;
}

.container h3 {
  text-transform: uppercase;
  width: 30rem;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  padding: 0.4rem 0rem;
  clip-path: polygon(95% 100%, 0% 100%, 0% 0%, 100% 0%);
  display: flex;
  padding-left: 2rem;
}

.checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkbox p {
  text-transform: uppercase;
  font-size: 1.4rem;
  color: var(--text-color);
  font-weight: 500;
  margin-bottom: 0.6rem;
}

.article__status {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
  border-radius: 0.4rem;
  position: relative;
}

.article__status-option {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
  z-index: 2;
  cursor: pointer;
}

.article__status-slider {
  position: absolute;
  height: 100%;
  width: 50%;
  border-radius: 0.4rem;
  background-color: var(--accent-color);
  transition: all 0.5s ease;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    padding: 4rem 1rem 1rem 1rem;
    column-gap: 1rem;
  }
  .container h3 {
    font-size: 1.4rem;
  }
  .checkbox p {
    font-weight: 400;
    font-size: 1.2rem;
  }
}

/* 800 */
@media (max-width: 50em) {
  .container h3 {
    width: 20rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 0.6rem;
  }
}
