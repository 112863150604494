/*  */

/* .container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 104rem 1fr;
  gap: 2rem;
  align-items: flex-start;
} */

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.inner-container {
  width: 100%;
  max-width: 104rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.card {
  min-height: 5rem;
  background-color: #fff;
  width: 100%;
  border-radius: 0.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* margin-bottom: 1rem; */
}

.filters-body {
  /* margin: 1rem 0; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: stretch;
  gap: 2rem;
  row-gap: 2rem;
  width: 100%;
  padding: 0rem 2rem;
}

.divider {
  margin: 1rem auto;
  height: 0.1rem;
  width: 96%;
  background-color: var(--main-color);
  /* background-color: #e0e0e0; */
}

.main {
  grid-column: 1/3;
}

/* TEST - KOMENTIRATI AKO SE VRACA NA STARO*/
.filter-footer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
}

.category {
  background-color: #fff;
  /* border: 0.2rem solid var(--background); */
  display: flex;
  flex-direction: column;
  gap: 0rem;
  /* align-items: center; */
  align-items: flex-start;
  width: 40rem;
  width: 100%;
  border-radius: 0.4rem;
}

.subcategory {
  background-color: #fff;
  /* border: 0.2rem solid var(--background); */
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  /* align-items: center; */
  align-items: flex-start;
  width: 40rem;
  width: 100%;
  border-radius: 0.4rem;
}

.sort {
  background-color: #fff;
  /* border: 0.2rem solid var(--background); */
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  align-items: flex-start;
  width: 30rem;
  width: 100%;
  border-radius: 0.4rem;
}
/*  */

.single-filter {
  padding: 0rem 0rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.6rem;
}

.single-filter h5 {
  align-self: flex-start;
  font-size: 1.4rem;
  color: var(--text-color);
  font-weight: 500;
  text-transform: uppercase;
}

/* ODKOMENTIRATI AKO SE VRACA NA STARO */
/* .filter-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

/* .category {
  background-color: var(--main-color);
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 40rem;
  border-radius: 0.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
} */

/* .sort {
  background-color: var(--main-color);
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 30rem;
  border-radius: 0.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
} */

.filter-footer h5 {
  /* border: 0.2rem solid var(--background); */
  border-bottom: none;
  /* border-radius: 0.4rem; */
  margin-left: 0rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  color: var(--text-color);
  /* height: 100%; */
  padding: 0rem 0rem 0.6rem 0rem;
}

.select {
  width: 100%;
}

.info {
  width: 100%;
  display: flex;
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--text-color);
  padding-left: 2rem;
}

.header {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2.4rem;
  color: var(--text-color);
  margin-bottom: 2rem;
  padding: 0.4rem 1rem 0.4rem 1rem;
  margin: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.header:hover {
  background-color: var(--background);
}

.header h4 {
  font-weight: 400;
}

.state-inputs {
  display: flex;
  gap: 1rem;
}

/* MEDIA QUERIES */
/* 1600 */
@media (max-width: 100em) {
  .container {
    grid-template-columns: 104rem 1fr;
  }
  .inner-container {
    margin-left: 2rem;
    width: calc(100% - 2rem);
  }
}

/* 1350 */
@media (max-width: 84.38em) {
  .inner-container {
    margin-left: 0rem;
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* 1100 */
@media (max-width: 68.75em) {
  .container {
    width: 100%;
    /* padding: 0 2rem; */
    gap: 1rem;
  }

  .inner-container {
    gap: 1rem;
    padding: 0 2rem;
    width: 100%;
    max-width: none;
  }

  .category h5 {
    font-weight: 400;
    font-size: 1.2rem;
  }

  .subcategory h5 {
    font-weight: 400;
    font-size: 1.2rem;
  }

  .sort h5 {
    font-weight: 400;
    font-size: 1.2rem;
  }

  .card {
    margin-bottom: 0rem;
  }

  .divider {
    margin: 1rem auto;
    width: 98%;
  }

  .filters-body {
    gap: 1rem;
    padding: 0rem 1rem;
  }

  .single-filter h5 {
    font-size: 1.2rem;
    font-weight: 400;
  }

  .header {
    font-size: 1.8rem;
    letter-spacing: 0.02rem;
    padding: 0.2rem 0rem 0.2rem 0rem;
  }
  .filter-footer {
    gap: 1rem;
  }
}

/* 1000 */
@media (max-width: 62.5em) {
  .category {
    /* flex-direction: column;
    justify-content: center;
    width: 20rem;
    gap: 0rem; */
  }
  .category h5 {
    /* margin: 0;
    font-size: 1.2rem;
    align-items: center;
    letter-spacing: 0.06rem;
    padding: 0.2rem 0; */
  }
  .sort {
    /* flex-direction: column;
    justify-content: center;
    width: 20rem;
    gap: 0rem; */
  }
  .sort h5 {
    /* margin: 0;
    font-size: 1.2rem;
    align-items: center;
    letter-spacing: 0.06rem;
    padding: 0.2rem 0; */
  }
}

/* 800px */
@media (max-width: 50em) {
  .filters-body {
    grid-template-columns: repeat(2, 1fr);
  }
  /* .category {
    gap: 1rem;
  }
  .sort {
    gap: 1rem;
  } */
  .filter-footer h5 {
    /* margin-left: 1rem;
    padding: 0 1rem; */
  }
}

/* 750px */
@media (max-width: 46.88em) {
  .filter-footer {
    flex-direction: column;
  }
  /* .filter-footer h5 {
    min-width: 20rem;
    margin-left: 1rem;
    padding: 0 1rem;
  } */
}

/* 600px */
@media (max-width: 37.5em) {
  .filter-footer {
    gap: 1rem;
  }
  /* .category {
    width: 100%;
    flex-direction: row;
  } */
  /* .category h5 {
    padding: 0.2rem 1rem;
  } */
  .sort {
    /* width: 100%; */
    display: none;
  }
}

/* 550px */
@media (max-width: 34.38em) {
  .container {
    padding: 0 1rem;
    gap: 1rem;
  }
  .inner-container {
    padding: 0rem;
    width: 100%;
    max-width: none;
  }
  .main {
    grid-column: 1/2;
  }
  .filters-body {
    grid-template-columns: 1fr;
    row-gap: 0.4rem;
  }
  .single-filter {
    padding: 0.2rem 0;
  }
  /* .filter-footer h5 {
    min-width: 14rem;
  } */
  .info {
    font-weight: 400;
    font-size: 1.6rem;
    padding-left: 0rem;
  }
}

/* 450px */
@media (max-width: 28.13em) {
  .filter-footer h5 {
    min-width: auto;
  }
}
