.container {
  width: 100%;
  min-height: calc(100vh - 8rem);
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  /* justify-content: flex-start; */
}

.body {
  width: 104rem;
  display: flex;
  /* align-items: flex-start; */
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    min-height: calc(100vh - 6rem);
  }
  .body {
    width: 100%;
    padding: 0 2rem;
  }
}

/* 900 */
@media (max-width: 56.25em) {
  /* .nav {
    font-size: 1.4rem;
    padding: 1rem 0 1rem 1rem;
    margin-left: 1rem;
  } */
}

/* 800 */
@media (max-width: 50em) {
  /* .navs {
    grid-column: 1/3;
    flex-direction: row;
    padding: 4rem 1rem 2rem 1rem;
  }
  .nav {
    flex: 1;
    border-radius: 0.2rem;
    padding: 1rem 1rem 1rem 1rem;
    margin: 0;
    text-align: center;
  } */
}

/* 700 */
/* @media (max-width: 43.75em) {
  .navs {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
} */

/* 650 */
@media (max-width: 40.63em) {
  .container {
    gap: 2rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .body {
    padding: 0 1rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  /* .nav {
    font-size: 1.2rem;
    padding: 1rem 0.4rem 1rem 0.4rem;
  } */
}

/* 400 */
@media (max-width: 25em) {
}
