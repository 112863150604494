.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.container h4 {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: var(--text1);
  font-weight: 500;
  margin-bottom: 0.6rem;
}

.selects {
  width: 100%;
  display: flex;
  align-items: center;
}

.subcategory {
  display: none;
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: var(--text-color);
}

.dropdown {
  width: 100%;
  border: 0.1rem solid rgb(89, 130, 146);
  border-radius: 0.6rem;
  color: var(--text3);
  padding: 1rem 2rem;
  font-size: 1.6rem;
}

.dropdown:focus {
  outline: none;
  border: 0.1rem solid rgb(92, 90, 228);
}

.arrow {
  height: 3rem;
  width: 3rem;
}

.icon {
  font-size: 3rem;
  color: var(--accent-color);
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container h4 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0.4rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .selects {
    flex-direction: column;
    gap: 0.6rem;
  }
  .subcategory {
    display: block;
  }
  .arrow {
    display: none;
  }
}
