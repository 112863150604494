.container {
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 5rem;
  display: flex;
  border: 0.1rem solid var(--main-color);
  overflow: hidden;
}

.color {
  width: 100%;
  height: 100%;
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    height: 3.4rem;
    width: 3.4rem;
  }
}
