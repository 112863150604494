.container {
  width: 100%;
  padding: 0 2rem;
  display: grid;
  grid-gap: 1rem;
  row-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    /* display: flex; */
  }
}

/* 1000 */
@media (max-width: 62.5em) {
}

/* 650px */
@media (max-width: 40.63em) {
  .container {
    row-gap: 1rem;
  }
}

/* 550px */
@media (max-width: 34.38em) {
  .container {
    padding: 0 1rem;
    gap: 0.8rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 350px */
/* @media (max-width: 21.28em) {
  .container {
    padding: 0 1rem;
    gap: 0.8rem;
    grid-template-columns: repeat(1, 1fr);
  }
} */

/* 300px */
@media (max-width: 18.75em) {
  .container {
    padding: 0 1rem;
    gap: 0.8rem;
    grid-template-columns: repeat(1, 1fr);
  }
}
