.container {
  width: 100%;
  background-color: var(--main-color);
  background-color: #fff;
  padding: 4rem 0rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  z-index: 20;
}

.container h4 {
  width: 100%;
  padding: 1rem 0;
  border-radius: 0.6rem;
  font-size: 2rem;
  color: var(--text-color);
  text-transform: uppercase;
}

.body {
  padding: 4rem 1rem 4rem 1rem;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;
  border-radius: 0.2rem;
  width: 104rem;
  background-color: var(--main-color);
  background-color: #fff;
}

.image-container {
  margin: 0 auto;
  width: 22rem;
  height: 22rem;
  background-color: #999;
  border: 0.2rem solid #ebebeb;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.image-container img {
  height: 100%;
}

.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 6rem;
  background-color: rgba(255, 255, 255, 0.274);
  background-color: rgba(43, 43, 43, 0.432);
  color: #666;
  transition: all 0.3s ease;
  animation: fade-in 0.5s ease;
}

.image-overlay label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.image-overlay input {
  display: none;
}

.image-overlay:hover {
  color: #ebebeb;
}

.image-overlay p {
  font-size: 1.8rem;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    /* padding: 4rem 2rem 2rem 2rem; */
  }
  .body {
    width: 100%;
    padding: 4rem 2rem 4rem 2rem;
  }
}

/* 900 */
@media (max-width: 56.25em) {
  .image-container {
    width: 16rem;
    height: 16rem;
  }
}

/* 650 */
@media (max-width: 40.63em) {
  .body {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .body {
    padding: 0 1rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
}

/* 400 */
@media (max-width: 25em) {
}
