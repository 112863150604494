.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* gap: 0.8rem; */
}

.container input {
  height: 4rem;
  width: 100%;
  /* width: 15rem; */
  background-color: var(--background);
  border: none;
  border-left: 0.4rem solid var(--background);
  border-radius: 0.4rem;
  color: var(--text-color);
  font-size: 1.6rem;
  padding: 0 2rem;
  text-align: center;
  transition: all 0.5s ease;
}

.container input:focus {
  outline: none;
  /* border: 0.1rem solid var(--main-color); */
  border-color: var(--main-color);
}

.container input::placeholder {
  color: var(--accent-secondary-color);
}

.container label {
  text-transform: uppercase;
  font-size: 1.4rem;
  color: var(--text1);
  font-weight: 500;
  margin-bottom: 0.6rem;
}

.container input::-webkit-outer-spin-button,
.container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.container input[type="number"] {
  -moz-appearance: textfield;
}

.error {
  color: red;
  height: 1.6rem;
  font-size: 1.4rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container label {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0.4rem;
  }

  .container input {
    /* height: 3.2rem; */
    font-size: 1.4rem;
  }
}

@media (max-width: 50em) {
  .container input {
    padding: 0 1rem;
  }
}
