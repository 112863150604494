.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  overflow: hidden;
}

.outer-circle {
  width: 100%;
  height: 100%;
  background-color: var(--accent-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-circle {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50rem;
  background-color: var(--background);
}

.rotation {
  width: 6rem;
  height: 1.8rem;
  background-color: var(--background);
  position: absolute;
  animation: rotate infinite ease 3s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(350deg);
  }
}
