.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 4rem;
  /* border: 0.1rem solid var(--border); */
  background-color: var(--background);
  padding: 0rem;
  border-radius: 0.4rem;
  position: relative;
}

.block {
  position: absolute;
  height: 100%;
  width: 35%;
  background-color: var(--accent-color);
  /* border: 0.6rem solid var(--accent-color-hover); */
  border-radius: 0.2rem;
  transition: all 0.5s ease;
}

.option {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-size: 1.6rem;
  width: 30%;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .option {
    font-size: 1.4rem;
  }
}
