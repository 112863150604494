.container {
  margin-top: 2rem;
  border-top: 0.2rem solid var(--main-color);
  padding: 1rem;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

.image-container {
  height: 12rem;
  width: 12rem;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-data {
  width: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.2rem;
  column-gap: 1rem;
}

.user-data h3 {
  font-size: 1.6rem;
  font-weight: 400;
  grid-column: 1/3;
  text-align: left;
  margin: 1rem 0 0.4rem 0;
}

.single-spec {
  height: 3.2rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  /* background-color: var(--background); */
  background-color: #fff;
  color: var(--text-color);
  padding: 0 1rem;
  border-radius: 0.2rem;
}

.single-spec h5 {
  font-size: 1.4rem;
  font-weight: 400;
  /* width: 40%; */
  text-align: start;
}

.single-spec p {
  font-size: 1.4rem;
  text-align: left;
  padding: 0.2rem 0;
}

.single-spec div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon {
  font-size: 1.8rem;
}

.btns {
  grid-column: 1/3;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.btn {
  font-size: 1.4rem;
  background-color: var(--main-color);
  color: #fff;
  flex: 1;
  border: none;
  height: 3rem;
  border-radius: 0.2rem;
  text-transform: uppercase;
  cursor: pointer;
}

/* 800 */
@media (max-width: 50em) {
  .user-data {
    /* grid-template-columns: repeat(1, 1fr); */
    display: flex;
    flex-direction: column;
  }
}
