.container {
  position: fixed;
  bottom: 0;
  height: 100vh;
  width: 100%;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: 1500;
}

.opacity {
  height: 100%;
  width: 100%;
  flex: 1;
  background-color: #0c0c0c5d;
  backdrop-filter: blur(0.04rem);
}

.footer {
  padding: 2rem 1rem;
  width: 100%;
  min-height: 20rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.body {
  display: flex;
  /* gap: 1rem; */
  width: 104rem;
  flex-direction: column;
  align-items: flex-start;
}

.body h5 {
  color: var(--text-color);
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.body p {
  font-size: 1.4rem;
  text-align: left;
  color: var(--text-color);
}

.link {
  font-size: 1.4rem;
  color: var(--main-color);
  margin-top: 0.4rem;
}

.btns {
  width: 104rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}

.btn {
  font-size: 1.6rem;
  /* text-transform: uppercase; */
  padding: 1rem 3rem;
  border: 0.1rem solid var(--border);
  background: none;
  border-radius: 0.2rem;
  cursor: pointer;
}

.accept {
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: #fff;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .footer {
    padding: 2rem;
  }
  .body {
    width: 100%;
  }
  .btns {
    width: 100%;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .footer {
    padding: 2rem 1rem;
  }
  .btns {
    margin-top: 1rem;
  }
}
