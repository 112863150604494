.container {
  /* margin-bottom: 16rem; */
  padding-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
}

.body {
  max-width: 104rem;
  width: 100%; /* Matches the header's .nav-container max-width */
  margin: 0 auto; /* Centers the container */
  display: grid;
  align-items: start;
  /* grid-template-columns: 0.6fr 2fr; */
  gap: 4rem;
  flex-direction: column;
  position: relative;
}

/* MEDIA QUERIES */
/* 1300 */
@media (max-width: 81.25em) {
}

/* 1100 */
@media (max-width: 68.75em) {
  .container {
    padding-top: 4rem;
  }
  .body {
    width: 100%;
    padding: 0 2rem;
    gap: 2rem;
  }
}

/* 950 */
@media (max-width: 59.38em) {
}

/* 900 */
@media (max-width: 56.25em) {
}

/* 800 */
@media (max-width: 50em) {
  .container {
    padding-top: 2rem;
  }
}

/* 600 */
@media (max-width: 37.5em) {
}

/* 550 */
@media (max-width: 34.38em) {
  .body {
    padding: 0 1rem;
    gap: 1rem;
  }
}
