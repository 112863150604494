.container {
  width: 100%;
  height: 5rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navs {
  width: 104rem;
  height: 100%;
  /* padding: 8rem 0 2rem 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  list-style: none;
  border-radius: 0.2rem;
  border-top-right-radius: 0rem;
}

.nav {
  text-align: left;
  text-transform: uppercase;
  /* margin-left: 2rem; */
  height: 100%;
  border-top: 0.6rem solid #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem 0 1rem 2rem; */
  /* border-top-left-radius: 0.6rem; */
  /* border-bottom-left-radius: 0.6rem; */
  color: var(--text-color);
  /* color: #fff; */
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav:hover {
  background-color: var(--background);
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .navs {
    width: 100%;
    padding: 0 2rem;
  }
  .nav {
    width: 100%;
    font-weight: 400;
  }
}

/* 1000 */
@media (max-width: 62.5em) {
  .nav {
    font-size: 1.2rem;
  }
}

/* 750 */
@media (max-width: 46.88em) {
  /* .container {
    height: 8.4rem;
    padding-bottom: 0.4rem;
  }
  .navs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .nav {
    border-radius: 0.2rem;
    border-top: none;
  } */
}

/* 550 */
@media (max-width: 34.38em) {
  .navs {
    padding: 0 1rem 0rem 1rem;
  }
}
