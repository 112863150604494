.container {
  width: 100%;
  /* height: 45rem; */
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-container {
  height: 100%;
  margin: 8rem 0 4rem 0;
  width: 104rem;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.image-container {
  /* align-self: fls; */
  width: 30rem;
  /* height: 100%; */
  background-color: var(--main-color);
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  border: 1rem solid var(--main-color);
  height: auto;
  border-radius: 1.4rem;
}

.image-container h4 {
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 1rem;
  /* border-top: 0.4rem solid #fff; */
  width: 100%;
  padding-top: 0.6rem;
}

.details {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.4rem;
  column-gap: 1rem;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.details h3 {
  grid-column: 1/3;
  text-align: left;
  font-size: 1.6rem;
  color: var(--text-color);
  font-weight: 500;
  padding-top: 2rem;
  padding-bottom: 1rem;
  /* margin-left: 1rem; */
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .inner-container {
    margin: 8rem 2rem 4rem 2rem;
    width: 100%;
  }
}

/* 1000 */
@media (max-width: 62.5em) {
  .image-container {
    width: 24rem;
  }
}

/* 900 */
@media (max-width: 56.25em) {
  .image-container {
    height: 100%;
  }
  .details {
    grid-template-columns: repeat(1, 1fr);
  }
  .details h3 {
    grid-column: 1/2;
  }
}

/* 750 */
@media (max-width: 46.88em) {
  .inner-container {
    flex-direction: column;
    align-items: center;
    margin: 4rem 1rem 2rem 1rem;
  }
  .details {
    width: 100%;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .inner-container {
    margin: 4rem 1rem 2rem 1rem;
  }
}
