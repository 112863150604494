.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  justify-content: center;
  gap: 2rem;
  box-shadow: rgba(65, 65, 65, 0.199) -4px 9px 25px -6px;
  padding: 6rem 2rem 1rem 2rem;
  border-radius: 1rem;
  background-color: #fff;
  position: relative;
  /* overflow: hidden; */
}

.container h3 {
  text-transform: uppercase;
  width: 30rem;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  padding: 0.4rem 0rem;
  clip-path: polygon(95% 100%, 0% 100%, 0% 0%, 100% 0%);
  display: flex;
  padding-left: 2rem;
}

.more-fields {
  width: 100%;
  grid-column: 1/3;
  display: grid;
  column-gap: 2rem;
  row-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  align-items: start;
  justify-content: center;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    padding: 4rem 1rem 1rem 1rem;
    column-gap: 1rem;
  }
  .container h3 {
    font-size: 1.4rem;
  }
  .more-fields {
    column-gap: 1rem;
  }
}

/* 800 */
@media (max-width: 50em) {
  .container h3 {
    width: 20rem;
  }
  .more-fields {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    /* grid-template-columns: repeat(1, 1fr); */
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }
  .more-fields {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 0.6rem;
  }
}
