.container {
  padding: 0rem;
  padding-top: 0rem;
  /* max-width: 104rem; */
  width: 100%; /* Matches the header's .nav-container max-width */
  margin: 0 auto; /* Centers the container */
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    /* padding-top: 1rem; */
    gap: 1rem;
  }
}

/* 600px */
@media (max-width: 37.5em) {
  .container {
    /* padding-top: 1rem; */
  }
}
