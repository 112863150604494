.activate-overlay__container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff8e;
  backdrop-filter: blur(4px);
  z-index: 50;
  animation: 0.5s fadeIn ease;
}

.activate-overlay__content {
  width: 90%;
  background-color: #fff;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 0.2rem solid var(--border);
  border-radius: 1rem;
  padding: 2rem 1rem;
}

.activate-overlay__container h5 {
  font-size: 1.8rem;
  font-weight: 400;
}

.activate-overlay__btns {
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.activate-overlay__btn {
  background-color: var(--button);
  color: #fff;
  width: 40%;
  padding: 1rem 2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.activate-overlay__btn:hover {
  background-color: var(--button-hover);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
