.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  text-transform: uppercase;
  font-size: 1.4rem;
  color: var(--text-color);
  font-weight: 500;
  margin-bottom: 0.6rem;
}

.input {
  height: 4rem;
  width: 100%;
  border-left: 0.4rem solid var(--background);
  border-radius: 0.4rem;
  display: flex;
  gap: 0.4rem;
  align-items: center;
  cursor: pointer;
  background-color: var(--background);
  padding: 0 1rem;
  transition: all 0.5s ease;
}

.hovered {
  border-color: var(--main-color);
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-color);
}

.input p {
  color: var(--text-color) !important;
  font-size: 1.6rem !important;
  text-transform: none !important;
  font-weight: 400 !important;
  height: 4rem;
  /* padding: 0 1rem; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ckecked {
  color: var(--main-color);
  border-color: var(--main-color);
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .label {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 0.4rem;
  }

  .input p {
    font-size: 1.4rem !important;
    /* height: 3.2rem; */
  }
}

@media (max-width: 50em) {
}
