.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  justify-content: center;
  column-gap: 2rem;
  row-gap: 1rem;
  box-shadow: rgba(65, 65, 65, 0.199) -4px 9px 25px -6px;
  padding: 6rem 2rem 2rem 2rem;
  border-radius: 1rem;
  background-color: #fff;
  position: relative;
  /* overflow: hidden; */
}

.container h3 {
  text-transform: uppercase;
  width: 30rem;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  padding: 0.4rem 0rem;
  clip-path: polygon(95% 100%, 0% 100%, 0% 0%, 100% 0%);
  display: flex;
  padding-left: 2rem;
}

.checkboxes {
  width: 100%;
  margin-top: 1rem;
  grid-column: 1/3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 0.4rem;
  column-gap: 1rem;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  border: 0.2rem solid var(--background);
  width: 100%;
  padding: 0 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkbox:hover {
  border-color: var(--accent-secondary-color);
}

.label {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--text-color);
  text-transform: uppercase;
}

.icon {
  font-size: 2rem;
  color: var(--main-color);
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    padding: 4rem 1rem 1rem 1rem;
    column-gap: 1rem;
  }
  .container h3 {
    font-size: 1.4rem;
  }
  .label {
    font-size: 1.2rem;
    font-weight: 400;
  }
}

/* 800 */
@media (max-width: 50em) {
  .container h3 {
    width: 20rem;
  }
  .checkboxes {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    /* grid-template-columns: repeat(1, 1fr); */
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }
  .checkboxes {
    grid-template-columns: repeat(1, 1fr);
    /* row-gap: 0.6rem; */
  }
}
