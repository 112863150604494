.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding-bottom: 2rem;
  background-color: #fff;
}

.btns {
  height: 8rem;
  display: flex;
  gap: 0.2rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-bottom: 0.1rem solid #dddddd;
}

.btn {
  width: 100%;
  height: 100%;
  /* background-color: var(--button); */
  color: var(--text-color);
  padding: 0.8rem 0;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: all 0.5s ease;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  background-color: #f6f6f6;
  color: #333;
}

.btn span {
  height: 1.8rem;
  width: 1.8rem;
  font-size: 1.4rem;
  color: #fff;
  background-color: red;
  border-radius: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-list {
  padding: 0 0.2rem 0 0;
  width: 100%;
  /* height: 100%; */
  flex: 1;
  overflow-y: auto;
}

.chat-list::-webkit-scrollbar {
  display: block;
  left: 0;
  width: 1rem;
}

.chat-list::-webkit-scrollbar-track {
  background: rgba(175, 175, 175, 0.034);
}

.chat-list::-webkit-scrollbar-thumb {
  background-color: var(--accent-secondary-color);
  border-radius: 2rem;
  border: 0.2rem transparent rgba(175, 175, 175, 0);
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .btn {
    font-size: 1.6rem;
    font-weight: 400;
  }
}

/* 1000 */
@media (max-width: 62.5em) {
  .btns {
    height: 6rem;
  }
  .btn {
    font-size: 1.4rem;
  }
}

/* 900 */
@media (max-width: 56.25em) {
  .btn {
    font-size: 1.2rem;
  }
}

/* 700 */
@media (max-width: 43.75em) {
  .container {
    grid-column: 1/3;
  }
}

/* 550 */
@media (max-width: 34.38em) {
}

/* 500 */
@media (max-width: 31.25em) {
}

/* 400 */
@media (max-width: 25em) {
}
