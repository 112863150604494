.container {
  padding: 2rem 2rem;
  width: 100%;
  height: calc(100vh - 8rem);
  height: calc((var(--vh, 1vh) * 100) - 8rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  overflow: auto;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    width: 100%;
    padding: 1rem;
    height: calc(100vh - 6rem);
    height: calc((var(--vh, 1vh) * 100) - 6rem);
  }
}
