.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  /* gap: 1rem; */
  align-items: center;
  overflow: hidden;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  animation: fade-in 0.3s ease-in;
}

.messages {
  padding: 2rem;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
  gap: 1rem;
  border-bottom: 0.1rem solid #dddddd;
}

.messages::-webkit-scrollbar {
  display: block;
  left: 0;
  width: 1rem;
}

.messages::-webkit-scrollbar-track {
  background: rgba(175, 175, 175, 0.034);
}

.messages::-webkit-scrollbar-thumb {
  background-color: var(--accent-secondary-color);
  border-radius: 2rem;
  border: 0.2rem transparent rgba(175, 175, 175, 0);
}

.message-form {
  width: 100%;
  height: 14rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.message-form textarea {
  flex: 1;
  height: 100%;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  color: #333;
  resize: none;
  border: none;
  background: none;
  border-left: 0.1rem solid #fff;
  transition: all 0.3s ease;
}

.message-form textarea:focus {
  outline: none;
  border-left: 0.1rem solid var(--button);
}

.message-form button {
  width: 10rem;
  height: 5rem;
  border: none;
  background-color: var(--main-color);
  color: #fff;
  font-size: 1.4rem;
  border-radius: 0.4rem;
  text-transform: uppercase;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.message-form button:hover {
  height: 100%;
  border-radius: 0;
  background-color: var(--main-color-hover);
}

.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-style: italic;
  color: #999;
  height: 3rem;
}

.inactive-input {
  background-color: #e3e3e3;
  color: #888;
}

.inactive {
  /* margin-right: 1rem; */
  width: 10rem;
  height: 100%;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: var(--accent-secondary-color);
  background-color: rgb(226, 226, 226);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* DETAILS */
.details {
  display: flex;
  height: 8rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 2rem;
  border-bottom: 0.1rem solid #dddddd;
}

.article-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.article-details h4 {
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--text-color);
}

.article-details p {
  font-size: 1.6rem;
  color: #777;
}

.icon {
  font-size: 2.4rem;
  color: var(--text-color);
}

.chat-options {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 4rem;
  height: 4rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.chat-options:hover {
  background-color: var(--background);
}

.options-dropdown {
  min-width: 20rem;
  /* padding: 2rem 0rem; */
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  list-style: none;
  background-color: #fff;
  /* border: 0.1rem solid #cfcfcf; */
  box-shadow: rgba(0, 0, 0, 0.123) 0px 1px 4px;
  /* border-radius: 0.4rem; */
  display: flex;
  flex-direction: column;
  z-index: 50;
}

.options-dropdown li {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0.4rem solid var(--white);
  font-size: 1.6rem;
  padding: 0.6rem 2rem;
  width: 100%;
  transition: all 0.3s ease;
  color: var(--text-color);
  cursor: pointer;
}
.options-dropdown li:hover {
  border-color: var(--main-color);
}

/* NO CHAT */
.no-chat {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-icon {
  font-size: 14rem;
  color: #fff;
}

.ended {
  align-self: center;
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 0.6rem;
  background-color: var(--alert);
  color: var(--text1);
  font-size: 1.6rem;
}

.btn-text {
  display: block;
}
.send {
  display: none;
  font-size: 2.4rem;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .article-details h4 {
    font-size: 1.6rem;
    font-weight: 400;
  }
  .ended {
    font-size: 1.4rem;
  }
  .message-form {
    height: 12rem;
  }
  .message-form button {
    height: 100%;
    border-radius: 0;
    margin-right: 0;
    font-size: 1.2rem;
  }
  .message-form button {
    background-color: #fff;
    color: var(--main-color);
  }
  .btn-text {
    display: none;
  }
  .send {
    display: block;
  }
}

/* 1000 */
@media (max-width: 62.5em) {
  .details {
    height: 6rem;
  }
}

/* 800 */
@media (max-width: 50em) {
  .article-details h4 {
    font-size: 1.4rem;
  }
  .messages {
    gap: 0.4rem;
  }
  .message-form {
    height: 10rem;
  }
  .message-form textarea {
    font-size: 1.2rem;
  }
  .message-form button {
    width: 8rem;
  }
  .ended {
    font-size: 1.2rem;
  }
}

/* 700 */
@media (max-width: 43.75em) {
  .container {
    grid-column: 1/3;
  }
}

/* 600 */
@media (max-width: 37.5em) {
  .messages {
    padding: 1rem;
  }
  .details {
    height: 8rem;
  }
  .message-form button {
    width: 6rem;
  }
  .message-form textarea {
    padding: 1rem;
  }
}
