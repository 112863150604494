.container {
  width: 18rem;
  height: 18rem;
  min-height: 14rem;
  overflow: hidden;
  position: relative;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.container img {
  width: 100%;
  height: auto;
  opacity: 0.3;
  transition: all 0.5s ease;
}

.container img:hover {
  opacity: 1;
}

.btns {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btns button {
  width: 50%;
  height: 4rem;
  background: none;
  border: none;
  background-color: rgba(231, 231, 231, 0.514);
  color: #333;
  /* background-color: rgba(34, 34, 34, 0.514); */
  cursor: pointer;
  transition: all 0.3s ease;
}

.btns button:hover {
  /* background-color: rgba(231, 231, 231, 0.9); */
  background-color: rgba(34, 34, 34, 0.9);
  color: #fff;
}

.icon {
  /* color: #fff; */
  font-size: 2.4rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    height: 10rem;
    width: 10rem;
    min-height: 10rem;
  }
  .btns button {
    height: 3.2rem;
  }
  .icon {
    font-size: 1.6rem;
  }
}
