.pause-overlay__container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff8e;
  backdrop-filter: blur(4px);
  z-index: 50;
  animation: 0.5s fadeIn ease;
}

.pause-overlay__content {
  width: 100%;
  background-color: #fff;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: 0.1rem solid var(--main-color);
  border-bottom: 0.1rem solid var(--main-color);
  /* border: 0.2rem solid var(--main-color); */
  /* border-radius: 1rem; */
  padding: 2rem 1rem;
}

.pause-overlay__container h5 {
  font-size: 1.8rem;
  color: var(--text-color);
  font-weight: 400;
}

.pause-overlay__btns {
  width: 100%;
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  gap: 1rem;
}

.pause-overlay__btn {
  background-color: var(--main-color);
  color: #fff;
  width: 50%;
  padding: 1rem 2rem;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.pause-overlay__btn:hover {
  background-color: var(--main-color-hover);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .pause-overlay__content {
    width: 100%;
    padding: 1rem;
    margin: 0 1rem;
  }
  .pause-overlay__container h5 {
    font-size: 1.6rem;
  }
  .pause-overlay__btns {
    font-size: 1.4rem;
    font-weight: 400;
  }
}

/* 450 */
@media (max-width: 28.13em) {
  .pause-overlay__container h5 {
    font-size: 1.4rem;
  }
  .pause-overlay__btns {
    font-size: 1.2rem;
  }
  .pause-overlay__btn {
    width: 100%;
  }
}
