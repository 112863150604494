.container {
  text-decoration: none;
  max-height: 25rem;
  height: 20rem;
  width: 50rem;
  min-width: 50rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 1rem;
  border-top-right-radius: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fade-in 0.5s ease-in;
  transition: all 0.5s ease;
}

.container:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.promoted {
  position: absolute;
  right: 0;
  background-color: var(--promoted-article);
  color: #fff;
  padding: 0.2rem 2rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  transform: translateY(-100%);
  font-size: 1.2rem;
  text-transform: uppercase;
}

.container h4 {
  width: 100%;
  font-size: 1.6rem;
  letter-spacing: 0.08rem;
  padding: 0.6rem 1rem;
  background-color: var(--promoted-article);
  color: #fff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.body {
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.image-container {
  width: 14rem;
  height: 14rem; /* 60% of the card's height */
  /* border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem; */
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: antiquewhite; */
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container and is zoomed in */
}

.description {
  font-size: 1.4rem;
  color: #444;
  padding: 0 1rem;
  text-align: justify;
}

.price {
  background-color: #f0f0f0;
  border-radius: 5rem;
  padding: 0.2rem 2rem;
  font-size: 1.8rem;
  font-weight: 600;
  color: #555;
}

.details {
  width: calc(100% - 15rem);
  height: 14rem;
  /* background-color: antiquewhite; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.details-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-detail-info {
  padding: 0.2rem 1.4rem;
  background-color: #f0f0f0;
  font-size: 1.2rem;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 5rem;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
