.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: var(--white);
}

.sections {
  /* margin-top: 4rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
