.container {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: fixed;
}

.inner-container {
  width: 100%;
  max-width: 130rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  flex: 1;
  font-size: 1.4rem;
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.5s ease;
}

.nav:hover {
  background-color: var(--background);
}

/* MEDIA QUERIES */
/* 800 */
@media (max-width: 50em) {
  .container {
    display: none;
  }
}
