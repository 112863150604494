.container {
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  justify-content: flex-start;
}

.container h4 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--text-color);
  border-left: 0.4rem solid var(--main-color);
  padding-left: 2rem;
}

.container p {
  font-size: 1.4rem;
  color: var(--text-color);
}

.reports {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .reports {
    margin-top: 1rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .container {
    margin-bottom: 1rem;
  }
}
