.modal-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}

.modal-message h4 {
  font-size: 2rem;
  color: var(--main-color);
  border-left: 0.6rem solid var(--main-color);
  padding-left: 2rem;
}

.btn {
  width: 100%;
  background-color: var(--main-color);
  padding: 0.8rem 0;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1rem;
  border-radius: 0.6rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.btn:hover {
  background-color: var(--main-color-hover);
}
