.buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.button {
  height: 4.4rem;
  margin: 0 2rem 2rem 2rem;
  background: none;
  background-color: var(--main-color);
  color: white;
  padding: 10px 40px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0.4rem;
  transition: all 0.2s ease-in-out;
}

.button:hover {
  background-color: var(--main-color-hover);
  /* transform: scale(1.1); */
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .button {
    margin: 0rem 1rem 1rem 0;
    height: 4rem;
    font-weight: 400;
  }
}

/* 450px */
@media (max-width: 28.13em) {
  .button {
    width: 100%;
    margin: 0 1rem 1rem 1rem;
  }
}
