.logo {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.logo-box {
  height: 4rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 100%;
  width: auto;
}

/* .red-box {
  background-color: var(--logo);
  border-radius: 1rem;
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.6rem;
} */
