.single__nav {
  font-size: 1.6rem;
  color: var(--text-color);
  /* text-transform: uppercase; */
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 0;
}

/* 550 */
@media (max-width: 34.38em) {
  .single__nav {
    /* font-size: 1.4rem; */
  }
  .logout-icon {
    font-size: 2rem;
  }
}
