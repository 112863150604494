.container {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* border-top-right-radius: 0.2rem; */
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0rem;
  row-gap: 1rem;
  column-gap: 2rem;
}

.content {
  height: 100%;
  min-height: 28rem;
  /* display: flex;
  flex-direction: column; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  background-color: #fff;
  border-radius: 0.4rem;
  padding: 2rem 2rem 2rem 2rem;
}

.container h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7rem;
  width: 100%;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: 500;
  padding: 2rem 0 2rem 4rem;
  border: 0.2rem solid var(--main-color);
  border-radius: 0.6rem;
  color: var(--text-color);
}

.container h5 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 2rem;
  text-align: left;
  margin-bottom: 0rem;
  grid-column: 1/3;
}

.checkbox {
  height: 4rem;
  width: 100%;
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: space-between;
  color: var(--accent-secondary-color);
  background-color: var(--background);
  padding: 0 1rem;
  border-radius: 0.4rem;
  font-size: 2rem;
  cursor: pointer;
}

.checkbox p {
  color: var(--text-color);
  height: 4rem;
  font-size: 1.4rem;
  /* width: 20rem; */
  display: flex;
  align-items: center;
  text-transform: uppercase;
  /* border-right: 0.1rem solid var(--input-separator); */
}

.buttons {
  grid-column: 1/3;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.buttons button {
  width: 100%;
  padding: 1rem 0;
  border: none;
  background-color: var(--main-color);
  color: #fff;
  font-size: 1.6rem;
  border-radius: 0.2rem;
  margin-top: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
}

.buttons button:hover {
  background-color: var(--main-color-hover);
}

/* IMAGE */

.image-container {
  margin: 0 auto;
  width: 22rem;
  height: 22rem;
  background-color: #999;
  border: 0.2rem solid #ebebeb;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.image-container img {
  height: 100%;
}

.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 6rem;
  background-color: rgba(255, 255, 255, 0.274);
  background-color: rgba(43, 43, 43, 0.432);
  color: #666;
  transition: all 0.3s ease;
  animation: fade-in 0.5s ease;
}

.image-overlay label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.image-overlay input {
  display: none;
}

.image-overlay:hover {
  color: #ebebeb;
}

.image-overlay p {
  font-size: 1.8rem;
}

.basic-info {
  display: flex;
  gap: 0.4rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1.6rem;
  color: var(--text-color);
}

.single-info {
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 4rem;
  font-weight: 500;
  border-radius: 0.4rem;
  border: 0.2rem solid var(--background);
}

.label {
  height: 100%;
  display: flex;
  min-width: 12rem;
  padding: 0 1rem;
  align-items: center;
  /* background-color: var(--background); */
  border-right: 0.2rem solid var(--background);
  font-size: 1.4rem;
  text-transform: uppercase;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .content {
    padding: 2rem 1rem 2rem 1rem;
    /* margin: 0 2rem; */
  }
  .buttons {
    /* gap: 0.4rem; */
  }
}

/* 900 */
@media (max-width: 56.25em) {
  .container h5 {
    font-size: 1.6rem;
    margin-top: 0rem;
  }
  .checkbox p {
    width: 18rem;
    font-size: 1.2rem;
  }
  .buttons button {
    font-size: 1.4rem;
  }
  .single-info {
    font-weight: 400;
  }
  .label {
    font-size: 1.2rem;
  }
}

/* 800 */
@media (max-width: 50em) {
  .container {
    /* grid-column: 1/3; */
  }
  .content {
    grid-template-columns: repeat(1, 1fr);
    padding: 1rem;
    gap: 0.4rem;
  }
  .container h5 {
    grid-column: 1/2;
    margin-top: 1rem;
  }
  .buttons {
    grid-column: 1/2;
  }
  .image-container {
    margin-bottom: 1rem;
  }
}

/* 700 */
@media (max-width: 43.75em) {
  .container {
    gap: 1rem;
  }
  .container h5 {
    /* margin-bottom: 1rem;
    margin-top: 2rem; */
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container h5 {
    font-size: 1.6rem;
  }
  .checkbox p {
    /* width: 16rem; */
    font-size: 1.2rem;
  }
  .single-info {
    font-size: 1.4rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .checkbox {
    width: 100%;
    gap: 0.2rem;
  }
  .checkbox p {
    border: none;
    /* width: 100%; */
    justify-content: space-between;
  }
  .buttons button {
    font-size: 1.2rem;
    flex: 1;
    height: 100%;
  }
  .content {
    gap: 0.4rem;
  }
}
