.container {
  height: 4rem;
  width: 104rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}

.single-button {
  height: 4rem;
  width: 4rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  border-radius: 0.6rem;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.report-button {
  height: 4rem;
  padding: 0 1rem;
  /* width: 4rem; */
  background-color: #fff;
  display: flex;
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  border-radius: 0.6rem;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.single-button:hover {
  background-color: var(--background);
}

.question {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 0rem;
  border: 0.2rem solid var(--accent-secondary-color);
  height: 4rem;
  border-radius: 0.6rem;
  padding: 0 1rem;
  font-size: 1.4rem;
  background-color: #fff;
  color: var(--text-color);
  animation: fade-in 0.5s ease-in;
}

.description {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.description p {
  background-color: #fff;
  height: 100%;
  padding: 0 4rem;
  border-radius: 0.6rem;
  font-size: 1.6rem;
  color: var(--text-color);
  font-weight: 500;
  display: flex;
  align-items: center;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.icon {
  font-size: 2.4rem;
  display: flex;
  color: var(--main-color);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.article-status {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20rem;
  height: 100%;
  border: 0.2rem solid var(--white);
  border-radius: 0.6rem;
  position: relative;
}

.article-status__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.article-status__button p {
  font-size: 1.6rem;
  flex: 1;
  color: var(--text-color);
}

.article-status__drowdown {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: -0.2rem;
  transform: translateY(100%);
  /* border: 0.1rem solid #dddddd; */
  box-shadow: rgba(0, 0, 0, 0.123) 0px 1px 4px;
  /* border-radius: 0.4rem; */
  z-index: 10;
}

.article-status__drowdown p {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  color: var(--text2);
  border-right: 0.4rem solid var(--white);
  cursor: pointer;
  transition: all 0.3s ease;
}

.article-status__drowdown p:hover {
  border-color: var(--main-color);
}

.report-article-text {
  font-size: 1.4rem;
}

/* MEDIA QUERIES */
/* 1300 */
@media (max-width: 81.25em) {
}

/* 1100 */
@media (max-width: 68.75em) {
  .container {
    width: calc(100% - 4rem);
  }
}

/* 600 */
@media (max-width: 37.5em) {
  .report-article-text {
    display: none;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    width: calc(100% - 2rem);
    gap: 0.4rem;
  }
  .description p {
    font-weight: 400;
    font-size: 1.4rem;
    padding: 0 1rem;
  }
  .article-status {
    min-width: 5rem;
  }
  .article-status__button p {
    font-size: 1.4rem;
  }
  .article-status__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .article-status__drowdown p {
    font-size: 1.4rem;
  }
  .question {
    height: 5rem;
    gap: 0.4rem;
    margin-right: 0.2rem;
    padding: 0 0.4rem;
    font-size: 1.4rem;
  }
  .single-button {
    min-width: 4rem;
  }
}
