.container {
  min-width: 35rem;
  max-width: 35rem;
  overflow: hidden;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
  border-radius: 0.6rem;
  padding: 1rem 1rem;
}

.header {
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.img {
  width: 12rem;
  height: 12rem;
  border-radius: 0.6rem;
}

.details {
  font-size: 1.4rem;
  color: var(--text-color);
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
}
.details p {
  text-align: left;
}
.username {
  text-decoration: none;
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 500;
  cursor: pointer;
}
.username:hover {
  color: #111;
}
.link {
  width: 100%;
  text-decoration: none;
  color: var(--text-color);
  font-size: 1.4rem;
  padding: 0.4rem 2rem;
  border: 0.2rem solid var(--accent-secondary-color);
  border-radius: 0.6rem;
  transition: all 0.3s ease;
}
.link:hover {
  color: #111;
  border-color: #bbbbbb;
}
.link span {
  text-transform: uppercase;
  font-weight: 500;
}

.contact {
  width: 100%;
  display: flex;
  gap: 0.2rem;
}

.btn {
  margin-top: 1rem;
  cursor: pointer;
  width: 50%;
  background-color: var(--main-color);
  color: #fff;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  padding: 0.6rem 0;
  transition: all 0.5s ease;
}
.btn:hover {
  background-color: var(--main-color-hover);
}

.inactive {
  background-color: #666;
  cursor: default;
}

.inactive:hover {
  background-color: #666;
}

.options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 4rem;
}

.button {
  height: 4rem;
  /* width: 4rem; */
  display: flex;
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
  border: 0.2rem solid var(--background);
  background-color: var(--background);
  border-radius: 0.6rem;
  font-size: 2.4rem;
  padding: 0 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: var(--white);
}

.report-text {
  font-size: 1.4rem;
}

/* MEDIA QUERIES */
/* 1300 */
@media (max-width: 81.25em) {
  .username {
    font-size: 1.6rem;
  }
  .link span {
    font-size: 1.2rem;
  }
}

/* 1100 */
@media (max-width: 68.75em) {
  .container {
    max-width: 30rem;
    min-width: 30rem;
  }
}

/* 950 */
@media (max-width: 59.38em) {
  .container {
    grid-row: 2/3;
    width: 100%;
    max-width: none;
    min-width: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 4rem 3rem 3rem;
    box-shadow: none;
    border-radius: 0;
    padding: 1rem;
    border: 0.2rem solid var(--background);
    border-radius: 1rem;
    /* border-top: 0.1rem solid var(--accent-secondary-color); */
  }
  .header {
    grid-column: 1/3;
    grid-row: 1/4;
  }
  .options {
    grid-column: 3/5;
  }
  .link {
    grid-column: 3/5;
  }
  .contact {
    grid-column: 3/5;
  }
  .btn {
    margin-top: 0;
  }
}

/* 800 */
@media (max-width: 50em) {
  .username {
    font-size: 1.4rem !important;
  }
  .details p {
    font-size: 1.2rem;
  }
  .btn {
    font-size: 1.4rem;
  }
}

/* 650 */
@media (max-width: 40.63em) {
  .container {
    gap: 0.4rem;
    padding: 1rem;
  }
  .img {
    height: 10rem;
    width: 10rem;
  }
  .details {
    gap: 0.4rem;
  }
  .header {
    gap: 1rem;
  }
}

/* 600 */
@media (max-width: 37.5em) {
  .container {
    display: flex;
  }
  .img {
    height: 8rem;
    width: 8rem;
  }
  .details {
    gap: 0.4rem;
  }
  .header {
    gap: 1rem;
    margin-bottom: 0.4rem;
  }
}

/* 600 */
@media (max-width: 37.5em) {
}
