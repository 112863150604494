.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* gap: 0.8rem; */
}

.input label {
  text-transform: uppercase;
  font-size: 1.4rem;
  color: var(--text-color);
  font-weight: 500;
  margin-bottom: 0.6rem;
}

.position {
  position: relative;
  /* background-color: #333; */
}

.input textarea {
  width: 100%;
  min-height: 20rem;
  max-height: 30rem;
  color: var(--text-color);
  font-size: 1.6rem;
  border: none;
  border-left: 0.4rem solid var(--background);
  background-color: var(--background);
  border-radius: 0.4rem;
  height: 4rem;
  padding: 1rem 2rem;
  resize: none;
  transition: all 0.5s ease;
}

.input textarea:focus {
  outline: none;
  border-color: var(--main-color);
}

.input textarea::placeholder {
  color: var(--accent-secondary-color);
}

.counter {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1.4rem;
  color: #555;
  transform: translateY(0%);
  /* z-index: 10; */
}

.error {
  color: red;
  height: 1.6rem;
  font-size: 1.4rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .input label {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0.4rem;
  }

  .input textarea {
    font-size: 1.4rem;
    min-height: 12rem;
    max-height: 20rem;
  }
}

@media (max-width: 50em) {
  .input textarea {
    padding: 1rem 1rem;
  }
}
