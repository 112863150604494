.container {
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.section {
  min-height: 18rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.6rem;
  padding: 2rem;
}

.section h4 {
  text-align: left;
  margin: 0 0 1rem 0;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--text-color);
  border-left: 0.4rem solid var(--main-color);
  padding-left: 2rem;
}

.search {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid var(--border);
  border-radius: 0.4rem;
  overflow: hidden;
}

.search input {
  flex: 1;
  height: 100%;
  border: none;
  padding: 0 1rem;
  font-size: 1.6rem;
}

.search input:focus {
  outline: none;
}

.search button {
  background: none;
  border: none;
  width: 4rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-size: 2.2rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .section {
    padding: 2rem 1rem 1rem 1rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    gap: 1rem;
  }
}
