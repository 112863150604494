.pagination-container {
  display: flex;
  gap: 0.2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0rem;
  position: relative;
}

.paginator {
  grid-row: 5 / 6;
  grid-column: 1 / 4;
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-items: center;
  gap: 0.6rem;
  list-style: none;
  /* background-color: var(--color-blue); */
}

.paginator li {
  padding: 0 0.5rem;
  min-width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff;
  background-color: var(--main-color);
  cursor: pointer;
  transition: all 0.3s;
}

.paginator li:hover {
  background-color: var(--main-color-hover);
  color: var(--white);
}

.active {
  background-color: var(--white) !important;
  transform: scale(1.2);
  font-weight: 600 !important;
  color: var(--text-color) !important;
  border: 0.2rem solid var(--color-blue);
  border-radius: 0.2rem;
  margin: 0 0.4rem;
}

.active:hover {
  color: #333 !important;
}

.margin__right {
  width: 5rem !important;
  border-radius: 0.2rem;
  /* border-top-right-radius: 5rem; */
  /* border-bottom-right-radius: 5rem; */
}

.margin__left {
  width: 5rem !important;
  /* border-top-left-radius: 5rem; */
  /* border-bottom-left-radius: 5rem; */
}

.pagination {
  padding: 0.2rem 2rem;
  letter-spacing: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: var(--gray-3);
  background-color: var(--light-4);
  border: 0.4rem solid var(--white);
  /* border-radius: 10rem; */
  position: absolute;
  bottom: 0;
  transform: translateY(120%);
}

/* .paginator-icon {
  width: 2rem;
  height: 2rem;
} */

/* MEDIA QUERIES */
/* 550 */
@media (max-width: 34.38em) {
  .paginator {
    gap: 0.2rem;
  }
  .paginator li {
    min-width: 2.8rem;
    height: 2.8rem;
    font-size: 1.2rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .pagination-container {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}

/* 450 */
@media (max-width: 28.13em) {
  .margin__left {
    width: 3rem !important;
  }
  .margin__right {
    width: 3rem !important;
  }
}

/* 350 */
@media (max-width: 21.88em) {
  .paginator li {
    min-width: 2.2rem;
    height: 2.2rem;
    font-size: 1.2rem;
  }
}
