.container {
  flex: 1;
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4rem;
}

.container h5 {
  font-size: 1.6rem;
  text-transform: uppercase;
  color: var(--text-color);
  padding-left: 1rem;
  border-left: 0.4rem solid var(--main-color);
}

.details-list {
  width: 100%;
  display: grid;
  row-gap: 0.4rem;
  column-gap: 1rem;
  /* justify-content: space-between; */
  /* align-items: center; */
  grid-template-columns: repeat(2, 1fr);
}

.single__detail-container {
  border: 0.2rem solid var(--background);
  border-radius: 0.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.single__detail-divider {
  width: 0.1rem;
  height: 100%;
  background-color: #dddddd;
}

.single__detail-key {
  text-transform: uppercase;
  padding: 0.6rem 1rem 0.6rem 1rem;
  min-width: 18rem;
  font-size: 1.4rem !important;
  background-color: var(--background);
  font-weight: 500;
  color: var(--text-color) !important;
}

.single__detail-value span {
  color: var(--unit-color) !important;
}

.single__detail-value {
  font-size: 1.6rem;
  color: var(--text-color);
}

.single__detail-icon {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  color: var(--main-color);
  margin-left: auto;
  margin-right: 1rem;
}

/* MEDIA QUERIES */
/* 1300 */
@media (max-width: 81.25em) {
  /* .description h5 {
    font-size: 1.6rem;
  }
  .description p {
    font-size: 1.6rem;
  } */
}

/* 1100 */
@media (max-width: 68.75em) {
  .single__detail-key {
    font-size: 1.2rem !important;
    width: 12rem;
    font-weight: 400 !important;
  }
  .single__detail-value {
    font-size: 1.4rem !important;
  }
}

/* 950 */
@media (max-width: 59.38em) {
  .single__detail-key {
    /* font-size: 1.4rem !important; */
    width: 12rem;
    font-weight: 400 !important;
  }
  .single__detail-value {
    font-size: 1.4rem !important;
  }
}

/* 900 */
@media (max-width: 56.25em) {
  .container {
    margin-top: 2rem;
    gap: 1rem;
  }
  .container h5 {
    font-weight: 500;
  }
}

/* 850 */
@media (max-width: 53.12em) {
  .single__detail-key {
    font-size: 1.2rem !important;
  }
  .single__detail-value {
    font-size: 1.4rem !important;
  }
}

/* 800 */
@media (max-width: 50em) {
  .container h5 {
    font-weight: 400;
  }
}

/* 750 */
@media (max-width: 46.88em) {
  .details-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* 650 */
@media (max-width: 40.63em) {
  .container h5 {
    font-size: 1.4rem;
  }
}

/* 600 */
@media (max-width: 37.5em) {
  .single__detail-key {
    font-size: 1.2rem !important;
  }
  .single__detail-value {
    font-size: 1.2rem !important;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .details-list {
    grid-template-columns: 1fr;
  }
}
