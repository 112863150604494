.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.container h4 {
  font-size: 1.8rem;
  text-transform: uppercase;
  color: var(--text-color);
  padding-left: 2rem;
  border-left: 0.4rem solid var(--main-color);
}

.container p {
  color: var(--text-color);
}

.container ul {
  list-style: none;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  height: 30rem;
  overflow-y: auto;
  border: 0.1rem solid var(--border);
  padding: 0.4rem;
  border-radius: 0.6rem;
}

.container li {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.2rem 2rem;
  font-size: 1.4rem;
  width: 100%;
  min-height: 10rem;
  text-align: left;
  background-color: var(--background);
  cursor: pointer;
}

.container li p {
  width: 90%;
}

.container button {
  border: none;
  background: none;
  background-color: var(--main-color);
  color: #fff;
  padding: 1rem 0;
  width: 100%;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.container button:hover {
  background-color: var(--main-color-hover);
}

.icon {
  font-size: 2.4rem;
  color: var(--text1);
}

/* MEDIA QUERIES */

/* 1100 */
@media (max-width: 68.75em) {
  .container {
    /* padding: 1rem; */
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container li p {
    width: 96%;
  }
  .container li {
    gap: 0.6rem;
    padding: 0.2rem 1rem;
  }
}
