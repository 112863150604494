.container {
  width: auto;
  height: 12rem;
  opacity: 0.5;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.container:hover {
  opacity: 0.8;
}

.img {
  width: auto;
  height: 100%;
}

/* 1100 */
@media (max-width: 68.75em) {
  .container {
    height: 8rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    height: 6rem;
  }
}
