.container {
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-column: 1/3;
}

.offers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  justify-content: center;
  gap: 2rem;
  width: 100%;
}

.offer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30rem;
  border-radius: 0.6rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
  cursor: pointer;
  color: var(--text-color);
  transition: all 0.3s ease;
}

.offer:hover {
  transform: scale(1.02);
  background-color: var(--background);
}

.offer h3 {
  width: 90%;
  text-align: left;
  align-self: flex-start;
  font-size: 2.4rem;
  padding: 1rem 2rem;
  clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 90% 100%);
  color: #fff;
  background-color: var(--main-color);
}

.description {
  flex: 1;
  font-size: 1.4rem;
  padding: 1rem;
  display: flex;
  margin-top: 2rem;
  align-items: center;
}

.coins {
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.price {
  background-color: var(--background);
  text-align: right;
  font-size: 2.4rem;
  align-self: flex-end;
  width: 90%;
  padding: 1rem 2rem;
  clip-path: polygon(100% 0%, 10% 0%, 0% 100%, 100% 100%);
}

.more-options {
  width: 100%;
  font-size: 1.4rem;
  color: var(--text-color);
  background-color: var(--background);
  padding: 1rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .offers {
    gap: 1rem;
  }
}

/* 900 */
@media (max-width: 56.25em) {
  .offers {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .offers {
    grid-template-columns: repeat(1, 1fr);
  }
}
