.container {
  width: 100%;
  max-width: 120rem;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.btns {
  display: none;
  height: 4rem;
  background-color: #fff;
  grid-column: 1/3;
  border-radius: 0.2rem;
  padding: 0 1rem;
  color: var(--main-color);
  align-items: center;
  justify-content: flex-start;
}

.btn {
  background-color: var(--background);
  border-radius: 0.4rem;
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.back-btn {
  width: auto;
  padding: 0 1rem;
  gap: 0.4rem;
}

.back {
  font-size: 1.4rem;
}

.small__screen-chats {
  display: none;
}
.small__screen-chat {
  display: none;
}
/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    width: 100%;
  }
}

/* 700 */
@media (max-width: 43.75em) {
  .container {
    grid-template-rows: auto 1fr;
  }
  .btns {
    display: flex;
  }
}
