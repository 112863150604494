.btn {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  border-radius: 0.4rem;
  color: #fff;
  font-size: 1.6rem;
  background-color: var(--main-color);
  font-weight: 600;
  letter-spacing: 0.1rem;
  padding: 1.4rem 2rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease;
}

.btn:hover {
  background-color: var(--main-color-hover);
}

.btn p {
  z-index: 10;
}

.background {
  /* background: linear-gradient(to right, #ae7aa9, #3c68b7); */
  position: absolute;
  height: 55rem;
  width: 55rem;
}

.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .btn {
    font-weight: 400;
    font-size: 1.6rem;
  }
}

/* 900 */
@media (max-width: 68.75em) {
  .btn {
    font-size: 1.4rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .btn {
    height: 4rem;
  }
}
