/*  */

.container {
  width: 100%; /* Ensures it scales with its container up to max-width */
  margin: 0 auto; /* Centers the block horizontally in its parent */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* background-color: var(--secondary-color);
  background-color: var(--main-color); */
  z-index: 900;
}

.nav-container {
  padding: 0 4rem;
  height: 8rem;
  width: 100%;
  /* max-width: 104rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-nav {
  display: flex;
  gap: 4rem;
}

/* .logo {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
} */

/* .red-box {
  background-color: var(--logo);
  border-radius: 1rem;
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.6rem;
} */

.links {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
}

.link {
  font-size: 1.7rem;
  color: var(--text-color);
  /* color: #fff; */
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: relative;
}

.link p {
  margin: 0;
  line-height: 2.5rem;
}

.icon {
  font-size: 2.2rem;
}

.userMenuItem {
  display: flex;
  position: relative;
}

.userMenuItem.__dropdown-icon {
  cursor: pointer;
}

.alert {
  position: absolute;
  top: 0.4rem;
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color: red;
  border-radius: 50rem;
  border: 0.2rem solid #fff;
  transform: translate(-50%, -50%);
}

.languageSelector {
  margin-left: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}

.languageSelector:focus {
  border-color: #007bff;
  outline: none;
}

.languages {
  width: 4rem;
  height: 4rem;
  position: relative;
}

.languages-btn {
  position: relative;
  height: 100%;
  width: 100%;
  color: var(--text-color);
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.languages-btn:hover {
  background-color: var(--background);
}

.lang {
  position: absolute;
  top: 0;
  right: 0.2rem;
  text-transform: uppercase;
}

.lang__dropdown {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: -1rem;
  transform: translateY(100%);
  /* left: 0; */
}

/* MEDIA QUERIES */
/* 1300 */
@media (max-width: 81.25em) {
  .link {
    font-size: 1.4rem;
  }
}
/* 1200 */
@media (max-width: 75em) {
}
/* 1100 */
@media (max-width: 68.75em) {
  .nav-container {
    display: none;
  }
}
