.container {
  display: none;
  gap: 0.8rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50rem;
  overflow: hidden;
  transition: all 0.3s ease;
}

.carousel-image {
  height: 100%;
  width: auto;
  min-width: 50rem;
  display: block;
}

.num__of__images {
  background-color: #fff;
  padding: 0.4rem 2rem;
  color: var(--text-color);
  border-radius: 9rem;
  font-size: 1.4rem;
  font-weight: 500;
}

.no-images {
  font-size: 1.4rem;
  color: var(--text-color);
}

/* MEDIA QUERIES */
/* 800 */
@media (max-width: 50em) {
  .container {
    display: flex;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .container {
    width: 100%;
  }
  .carousel {
    width: 100%;
    height: auto;
    min-height: 30rem;
    background-color: #fff;
  }
  .carousel-image {
    width: 100%;
    height: auto;
    min-width: 10rem;
  }
}
