.container {
  width: 100%;
  max-width: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-right: 1rem solid var(--message);
  border-radius: 0.2rem;
  color: var(--text-color);
  font-size: 1.6rem;
  padding: 3rem 2rem;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 2000;
  transition: all 0.5s ease;
  animation: enter-from-right 0.5s ease;
}

@keyframes enter-from-right {
  from {
    transform: translateX(120%);
  }
  to {
    transform: translateX(0);
  }
}

/* MEDIA QUERIES */
/* 700 */
@media (max-width: 43.75em) {
  .container {
    max-width: calc(100% - 4rem);
    padding: 2rem 1rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    max-width: calc(100% - 2rem);
    right: 1rem;
  }
}
