.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.single {
  height: 100%;
  max-height: 4rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: var(--background);
  border-radius: 0.4rem;
  padding: 0 0 0 1rem;
}

.error {
  font-size: 1.2rem;
  color: var(--error-msg);
}

.single label {
  height: 4rem;
  font-size: 1.4rem;
  /* width: 20rem; */
  display: flex;
  align-items: center;
  text-transform: uppercase;
  /* border-right: 0.1rem solid var(--input-separator); */
  color: var(--text-color);
}

.single input {
  flex: 1;
  border: none;
  padding: 1rem 1rem;
  background: none;
  font-size: 1.6rem;
  color: var(--text-color);
  border-right: 0.4rem solid var(--background);
  border-radius: 0.4rem;
  transition: all 0.3s ease;
}

.single input:hover {
  /* background-color: #f6f6f6; */
}

.single input:focus {
  outline: none;
  /* border-right: 0.2rem solid var(--main-color); */
  border-color: var(--main-color);
}

.single input::-webkit-outer-spin-button,
.single input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.single input[type="number"] {
  -moz-appearance: textfield;
}

/* MEDIA QUERIES */
/* 900 */
@media (max-width: 56.25em) {
  .container label {
    font-size: 1.2rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .single label {
    font-size: 1.2rem;
  }
  .single input {
    font-size: 1.4rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .single {
    width: 100%;
    gap: 0.2rem;
  }
  .single label {
    /* width: 100%; */
    border: none;
    height: 2rem;
  }
  .single input {
    width: 100%;
    background-color: var(--background);
  }
}
