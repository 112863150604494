.container {
  background-color: var(--secondary-color);
  background-color: #fff;
  width: 100%;
  min-height: 40rem;
  margin-top: 2rem;
  padding: 4rem 20rem 0 20rem;
  display: flex;
  flex-direction: column;
}

.footer {
  width: 100%;
  flex: 1;
  display: flex;
}

.footer h4 {
  font-size: 1.6rem;
  width: 60%;
  text-align: left;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--text-color);
  padding-bottom: 1rem;
  /* border-bottom: 0.2rem solid var(--main-color); */
  /* border-bottom: 0.2rem solid var(--accent-color); */
  /* margin-bottom: 1rem; */
}

.group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.link {
  font-size: 1.6rem;
  text-decoration: none;
  color: var(--text-color);
}

.links {
  flex: 1;
  /* display: flex; */
  display: grid;
  column-gap: 4rem;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
}

.sub-footer {
  align-self: flex-end;
  width: 100%;
  height: 14rem;
  display: flex;
  gap: 0.4rem;
  color: var(--text3);
  align-items: center;
  font-size: 1.4rem;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.details h5 {
  font-size: 1.6rem;
  text-transform: uppercase;
  color: var(--text1);
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.details-single {
  color: var(--text-color);
}

.logo-box {
  height: 8rem;
  width: auto;
}

.logo {
  align-self: center;
  /* background-color: var(--logo); */
  color: #fff;
  height: 100%;
  width: auto;
  /* border-radius: 1rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
}

.separator {
  flex: 1;
}

.languages {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.lang {
  width: 3.8rem;
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  color: var(--text-color);
  background-color: #fff;
  border: 0.1rem solid var(--background);
  border-radius: 0.6rem;
  cursor: pointer;
}

/* MEDIA QUERIES */
/* 1400 */
@media (max-width: 87.5em) {
  .container {
    padding: 4rem 10rem 0rem 10rem;
  }
}
/* 1300 */
@media (max-width: 81.25em) {
  .container {
    margin-top: 2rem;
  }
  .link {
    font-size: 1.4rem;
  }
  .lang {
    height: 3rem;
    width: 3rem;
    font-size: 1.4rem;
  }
}
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    min-height: 34rem;
    padding: 4rem 2rem 0 2rem;
  }
  .links {
    column-gap: 2rem;
  }
  .footer h4 {
    font-size: 1.4rem;
  }
  .details h5 {
    font-size: 1.4rem;
  }
  .sub-footer {
    height: 10rem;
  }
}

/* 900 */
@media (max-width: 56.25em) {
  .links {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2rem;
  }
  .third-column {
    display: none;
  }
  .languages {
    gap: 1rem;
  }
  .lang {
    height: 2.4rem;
    width: 2.4rem;
    font-size: 1.2rem;
  }
  .sub-footer {
    font-size: 1.2rem;
  }
}

/* 650 */
@media (max-width: 40.62em) {
  .links {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2rem;
  }
  .footer h4 {
    width: 100%;
    margin-bottom: 0.2rem;
  }
  .details {
    grid-row: 1/2;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
  .details-single {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .details h5 {
    margin: 0rem 0 1rem 0;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .container {
    margin-top: 1rem;
  }
}
