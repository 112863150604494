.container {
  margin-top: 2rem;
  border-top: 0.2rem solid var(--main-color);
  padding: 1rem;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

.details {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 0.2rem;
}

.details h3 {
  font-size: 1.6rem;
  font-weight: 400;
  grid-column: 1/3;
  text-align: left;
  margin: 1rem 0 0.4rem 0;
}

.image-container {
  height: 12rem;
  width: 12rem;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-span {
  grid-column: 1/3;
}

.description {
  grid-column: 1/3;
  /* grid-row: 5/8; */
  height: 100%;
}

.btns {
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
}

.btn {
  background-color: var(--main-color);
  color: #fff;
  font-size: 1.4rem;
  text-transform: uppercase;
  width: 100%;
  border: none;
  padding: 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
}

/* MEDIA QUERIES */
/* 800 */
@media (max-width: 50em) {
  .details {
    /* grid-template-columns: repeat(1, 1fr); */
    display: flex;
    flex-direction: column;
  }
  .btns {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.2rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .btn {
    font-size: 1.2rem;
  }
}

/* 400 */
@media (max-width: 25em) {
  .btns {
    grid-template-columns: repeat(1, 1fr);
  }
}
