.search-container {
  width: 100%;
  background-color: #fff;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
}

.input {
  height: 4rem;
  height: 100%;
  flex: 1;
  border: none;
  border-left: 0.4rem solid #fff;
  /* border: 0.1rem solid #a9a9a9; */
  /* border: none; */
  font-size: 1.6rem;
  color: var(--text-color);
  padding: 0rem 2rem;
  border-radius: 0.4rem 0 0 0.4rem;
  border-right: none;
  transition: all 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: var(--main-color);
}

.input::placeholder {
  color: var(--accent-secondary-color);
}

.search {
  height: 100%;
  width: 4rem;
  border: 0.1rem solid var(--accent-secondary-color);
  border: none;
  border-radius: 0 0.4rem 0.4rem 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: none;
  cursor: pointer;
}

.icon {
  width: 2rem;
  height: 2rem;
  background: none;
  background-color: #fff;
  color: var(--main-color);
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .input {
    font-size: 1.4rem;
  }
}
