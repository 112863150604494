.btn {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  padding: 1rem 4rem;
  border: 0.2rem solid #fff;
  color: #fff;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.btn:hover {
  padding: 1rem 5rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .btn {
    font-size: 1.8rem;
    font-weight: 400;
  }
}

/* 900 */
@media (max-width: 68.75em) {
  .btn {
    font-size: 1.4rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .btn {
    height: 4rem;
    border-radius: 0.2rem;
    padding: 1rem 2rem;
  }
}
