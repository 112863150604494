.container {
  grid-column: 1 / 3;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* margin-bottom: 2rem; */
  gap: 2rem;
  row-gap: 1rem;
}

.container h3 {
  font-size: 2rem;
  font-weight: 400;
  color: var(--text-color);
  text-transform: uppercase;
}
/*  */
.vehicles-body {
  width: 100%;
  display: grid;
  column-gap: 2rem;
  row-gap: 1.4rem;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
}

.divider {
  align-self: center;
  height: 0.1rem;
  width: 96%;
  background-color: var(--main-color);
  /* background-color: #e0e0e0; */
}

.divider-second {
  align-self: center;
  height: 0.1rem;
  width: 90%;
  background-color: var(--background);
  /* background-color: #e0e0e0; */
}

/* BODY TWO */
.vehicles-body__two {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.single-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.single-input h4 {
  text-transform: uppercase;
  font-size: 1.4rem;
  color: var(--text-color);
  font-weight: 500;
  margin-bottom: 1rem;
}

.inputs {
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.dash {
  width: 2rem;
  height: 0.2rem;
  background-color: var(--main-color);
  /* margin-bottom: 1rem; */
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .divider {
    width: 100%;
  }
  .container {
    gap: 1rem;
    /* margin-bottom: 1rem; */
  }
  .vehicles-body {
    gap: 1rem;
  }
  .vehicles-body__two {
    gap: 1rem;
  }
  .single-input h4 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0.6rem;
  }
}

/* 800 */
@media (max-width: 50em) {
  .container {
    grid-column: 1 / 3;
  }
  .vehicles-body {
    grid-template-columns: repeat(2, 1fr);
  }
  .vehicles-body__two {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    grid-column: 1/1;
  }
  .vehicles-body {
    grid-template-columns: 1fr;
  }
  .vehicles-body__two {
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 0.4rem;
  }
  .inputs {
    width: 100%;
  }
}
