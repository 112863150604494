.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
}

.image-container__left {
  align-self: flex-start;
  margin-right: auto;
  margin-left: 2rem;
  width: 25rem;
  height: 40rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0.4rem;
  overflow: hidden;
}

.image-container__horizontal {
  align-self: flex-start;
  /* margin-left: auto; */
  width: 104rem;
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0.4rem;
  overflow: hidden;
}

.image-container__right {
  align-self: flex-start;
  margin-left: auto;
  margin-right: 2rem;
  width: 25rem;
  height: 40rem;
  display: flex;
  /* align-items: center; */
  /* justify-content: flex-end; */
  border-radius: 0.4rem;
  overflow: hidden;
}

.container img {
  width: 100%;
  height: 100%;
}

.hidden1600 {
  display: flex;
}

.hidden1350 {
  display: flex;
}

.hidden1050 {
  display: flex;
}

/* MEDIA QUERIES */
/* 1600 */
@media (max-width: 100em) {
  .hidden1600 {
    display: none;
  }
}

/* 1350 */
@media (max-width: 84.38em) {
  .hidden1350 {
    display: none;
  }
}

/* 1050 */
@media (max-width: 65.63em) {
  .hidden1050 {
    display: none;
  }
}
