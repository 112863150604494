.container {
  width: 100%;
  background-color: #fff;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-container {
  width: 100%;
  max-width: 104rem;
  height: 100%;
  display: flex;
  gap: 0.4rem;
  align-items: center;
  justify-content: space-between;
}

.btn-container {
  width: 100%;
  height: 100%;
}

.btns {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  color: var(--text-color);
  border-top: 0.6rem solid #fff;
  transition: all 0.5s ease;
}

.btns:hover {
  background-color: var(--background);
}

.btns span {
  font-weight: 400;
}

.active {
  border-color: var(--active);
  background-color: var(--background);
}
.active:hover {
}

.pending {
  border-color: var(--pending);
  background-color: var(--background);
}
.pending:hover {
}

.pause {
  /* background-color: var(--pause); */
  border-color: var(--pause);
  background-color: var(--background);
}
.pause:hover {
  /* background-color: var(--pause-hover); */
}

.deleted {
  /* background-color: var(--deleted); */
  border-color: var(--deleted);
  background-color: var(--background);
}
.deleted:hover {
  /* background-color: var(--deleted-hover); */
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    width: 100%;
    padding: 0rem 2rem;
  }
  .inner-container {
    width: 100%;
  }
  .btns {
    font-size: 1.4rem;
    font-weight: 400;
  }
}

/* 1000 */
@media (max-width: 62.5em) {
  .btns {
    font-size: 1.2rem;
  }
}

/* 750 */
@media (max-width: 46.88em) {
  .container {
    height: 8.4rem;
    padding-bottom: 0.4rem;
    /* display: grid; */
  }
  .inner-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .btns {
    border-radius: 0.2rem;
    border-top: none;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    padding: 0 1rem 0.4rem 1rem;
  }
}
