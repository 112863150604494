.container {
  font-size: 1.6rem;
  color: var(--text-color);
  color: #fff;
  background-color: var(--main-color);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  margin-top: 2rem;
  width: 100%;
  padding: 1rem;
}
