.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.container input {
  height: 4rem;
  width: 100%;
  /* width: 15rem; */
  /* border: 0.1rem solid var(--border); */
  background-color: var(--background);
  border-radius: 0.4rem;
  border: none;
  border-left: 0.4rem solid var(--background);
  color: var(--text-color);
  font-size: 1.6rem;
  padding: 0 2rem;
  text-align: center;
  transition: all 0.3s ease;
}

.container input:focus {
  outline: none;
  border-color: var(--main-color);
}

.container input::placeholder {
  color: var(--accent-secondary-color);
}

/* Chrome, Safari, Edge, Opera */
.container input::-webkit-outer-spin-button,
.container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.container input[type="number"] {
  -moz-appearance: textfield;
}

.dash {
  width: 2rem;
  height: 0.2rem;
  background-color: var(--main-color);
}

.inputs {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    gap: 0.6rem;
  }

  .container input {
    font-size: 1.4rem;
    /* height: 3.2rem; */
  }
}

@media (max-width: 50em) {
  .container input {
    padding: 0 1rem;
  }
}
