.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: rgba(65, 65, 65, 0.199) -4px 9px 25px -6px;
  padding: 6rem 2rem 2rem 2rem;
  border-radius: 1rem;
  border-top-left-radius: 0;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}

.container h3 {
  text-transform: uppercase;
  width: 30rem;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  padding: 0.4rem 0rem;
  clip-path: polygon(95% 100%, 0% 100%, 0% 0%, 100% 0%);
  display: flex;
  padding-left: 2rem;
}
/* 
.images-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-start;
}

.add-icon {
  font-size: 2rem;
}

.images-label {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  color: #333;
  background-color: #c7c7c7;
  cursor: pointer;
  transition: all 0.3s;
}

.images-label:hover {
  color: #222;
  background-color: #b8b8b8;
}

.images-label p::first-letter {
  font-size: 2rem;
}

.images-input {
  display: none;
} */

.images-container {
  height: 100%;
  width: 100%;
  justify-self: stretch;
  position: relative;
}

.images-container__background {
  color: #fff;
  font-size: 14rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    padding: 4rem 1rem 1rem 1rem;
  }
  .container h3 {
    font-size: 1.4rem;
  }
}

/* 800 */
@media (max-width: 50em) {
  .container h3 {
    width: 20rem;
  }
}
