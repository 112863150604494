.container {
  position: relative;
  width: 45rem;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 6rem;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: fade-in 1.5s ease;
}

.container h2 {
  font-size: 4.2rem;
  color: var(--text-color);
  font-weight: 500;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.form {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  margin-top: 4rem;
}

.input-field {
  position: relative;
  width: 100%;
}

.form input {
  /* width: 30rem; */
  color: var(--text-color);
  background-color: var(--background);
  width: 100%;
  letter-spacing: 0.14rem;
  font-size: 1.6rem;
  height: 4.4rem;
  padding: 0 2rem;
  border: none;
  border-radius: 0.4rem;
  border-left: 0.4rem solid var(--background);
  transition: all 0.5s ease;
}

.form input:focus {
  outline: none;
  border-color: var(--main-color);
}

.form input::placeholder {
  color: var(--accent-secondary-color);
}

.form span {
  font-size: 2.4rem;
  color: var(--main-color);
  margin: 2rem 0;
}

.icon {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  font-size: 2rem;
  cursor: pointer;
  color: var(--text-color);
  transition: all 0.5s ease;
}

.icon:hover {
  color: #d66b6b;
}

.btn {
  background: none;
  border: 0.2rem solid rgb(24, 104, 253);
  background-color: rgb(24, 104, 253);
  color: #fff;
  font-size: 2rem;
  padding: 0.4rem 4rem;
  border-radius: 50rem;
  margin: 2rem 0;
}

.signup {
  font-size: 1.6rem;
}

.password-forgot {
  font-size: 1.4rem;
  color: var(--text-color);
  align-self: flex-start;
  margin-bottom: 3.2rem;
  margin-top: 0.2rem;
}

.gmail-login {
  height: 5rem;
  width: 5rem;
  border: 0.2rem solid #c3c3c3;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.gmail-login:hover {
  border-color: #999;
}

.gmail-icon {
  font-size: 3.2rem;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    width: 35rem;
  }
  .container h2 {
    font-size: 2.8rem;
  }
  .form {
    width: 90%;
    gap: 1rem;
  }
  .form input {
    font-size: 1.4rem;
    height: 4rem;
  }
  .password-forgot {
    font-size: 1.2rem;
  }
}

/* 900 */
@media (max-width: 68.75em) {
  .container {
    max-height: 50rem;
    width: 30rem;
  }
  .container h2 {
    font-size: 2.4rem;
  }
  .form input {
    font-size: 1.2rem;
  }
}

/* 800 */
@media (max-width: 50em) {
}

/* 650 */
@media (max-width: 40.63em) {
  .container {
    max-height: none;
    width: 50%;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    width: 60%;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .container {
    width: 100%;
    flex: 1;
    border-radius: 0;
    gap: 0.2rem;
    padding: 1rem;
  }
  .form {
    gap: 0.4rem;
    margin-top: 1rem;
    width: 100%;
  }
  .container h2 {
    font-size: 2rem;
  }
}
