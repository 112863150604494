.container {
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2rem;
  position: relative;
}

.container h4 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--text-color);
  border-left: 0.4rem solid var(--main-color);
  padding-left: 2rem;
}

.container p {
  font-size: 1.4rem;
  color: var(--text-color);
}

.reports {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.messages-container {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70rem;
  width: 104rem;
  max-height: calc(100% - 8rem);
  background-color: rgba(53, 53, 53, 0.247);
  background-color: var(--background);
  border: 0.1rem solid var(--accent-secondary-color);
  backdrop-filter: blur(0.6rem);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0rem;
  border-radius: 0.6rem;
  z-index: 20;
}

.messages-container__msgs {
  /* background-color: #ffffffc5; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem 2rem;
  overflow-y: auto;
}

.close {
  background-color: #fff;
  margin-right: 2rem;
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  border-radius: 0.4rem;
  font-size: 2rem !important;
  cursor: pointer;
}

.single-message {
  background-color: #ffffffec;
  min-width: 30rem;
  padding: 0.8rem 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .reports {
    margin-top: 1rem;
  }
  .messages-container {
    width: 100%;
    height: calc(100% - 4rem);
    max-height: none;
    top: calc(50% + 3rem);
    border: none;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .messages-container__msgs {
    padding: 1rem;
  }
  .single-message {
    padding: 1rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .container {
    margin-bottom: 1rem;
  }
}

/* 450 */
@media (max-width: 28.13em) {
  .single-message {
    min-width: 10rem;
  }
  .single-message p {
    font-size: 1.2rem !important;
  }
}
