.userDropdown {
  width: 100%;
  position: absolute;
  top: 54px;
  left: 0px;
  transform: translateX(-30%);
  /* border: 1px solid #ccc; */
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 220px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.123) 0px 1px 4px;
  /* padding: 3rem 2rem; */
}

/* .userDropdown::before {
  content: "";
  position: absolute;
  left: calc(50% - 2rem * sqrt(2) / 2);
  top: -1.1rem;
  width: 2rem;
  height: 2rem;
  background: #fff;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  transform: rotate(45deg);
} */

.userDropdown__link {
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;
  border-right: 0.4rem solid #fff;
  /* margin-bottom: 1rem; */
  font-size: 1.6rem;
  color: var(--text-color);
  padding: 1rem 3rem;
  transition: all 0.3s ease;
}
.userDropdown__link:hover {
  /* background-color: var(--background); */
  border-right: 0.4rem solid var(--main-color);
}

.userDropdown__link:last-child {
  margin-bottom: 0;
}
