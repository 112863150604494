.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  text-transform: uppercase;
  font-size: 1.4rem;
  color: var(--text-color);
  font-weight: 500;
  margin-bottom: 0.6rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .label {
    font-weight: 400;
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
  }
}
