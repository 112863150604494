.container {
  /* background-color: aqua; */
  background-color: #fff;
  padding: 2rem 1rem 1rem 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.6rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  /* position: fixed;
  left: 4rem; */
}

.tab {
  width: 100%;
  /* background-color: var(--admin-panel-btn); */
  display: flex;
  color: var(--text-color);
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 1rem 1rem 1rem 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab:hover {
  background-color: var(--background);
}

.link {
  width: 100%;
  padding: 1rem 0rem 1rem 2rem;
  /* background-color: var(--admin-panel-btn); */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  color: var(--text-color);
  transition: all 0.3s ease;
}

.link:hover {
  background-color: var(--admin-panel-btn-hover);
  background-color: var(--background);
}

.sublinks {
  position: absolute;
  bottom: -0.2rem;
  transform: translateY(100%);
  width: 96%;
  background-color: #fff;
  /* margin-left: 6rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.6rem 1rem;
  border-radius: 0.4rem;
  border: 0.1rem solid var(--accent-secondary-color);
  z-index: 10;
}

.sublink {
  width: 100%;
  padding: 1rem 0 1rem 4rem;
  /* background-color: var(--admin-panel-btn); */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  color: var(--text-color);
  transition: all 0.3s ease;
}

.sublink:hover {
  background-color: var(--background);
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .link {
    font-weight: 400;
  }
  .tab {
    font-weight: 400;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .link {
    font-size: 1.4rem;
  }
  .tab {
    font-size: 1.4rem;
  }
  .sublinks {
    font-size: 1.4rem;
  }
}

/* 450 */
@media (max-width: 28.13em) {
  .link {
    padding: 1rem 0 1rem 1rem;
  }
  .tab {
    padding: 1rem 0 1rem 1rem;
  }
  .sublink {
    padding: 1rem 0 1rem 2rem;
  }
}
