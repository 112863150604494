.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 4rem;
}

.header {
  width: 104rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.description {
  display: flex;
  gap: 0.6rem;
}

.header p {
  font-size: 1.6rem;
  color: var(--text-color);
}

.header span {
  font-size: 1.6rem;
  color: var(--text-color);
  font-weight: 500;
  text-transform: uppercase;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.articles-container {
  width: 100%;
  padding: 0 2rem;
  display: grid;
  grid-gap: 1rem;
  row-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.footer {
  margin-top: 2rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .header {
    width: 100%;
    padding: 0 2rem;
  }
  .footer {
    margin-top: 1rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .header {
    padding: 0 1rem;
  }
  .articles-container {
    padding: 0 1rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .footer {
    margin-top: 0rem;
  }
}
