.container {
  height: calc((var(--vh, 1vh) * 100) - 8rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  background-color: #fff;
  padding: 4rem 2rem;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.card h4 {
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--text-color);
}
.btn {
  background-color: var(--main-color);
  color: #fff;
  padding: 1rem;
  width: 100%;
  font-size: 1.6rem;
  text-decoration: none;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    height: calc((var(--vh, 1vh) * 100) - 6rem);
  }
}

/* 650px */
@media (max-width: 40.63em) {
  .card h4 {
    font-size: 1.6rem;
  }
  .btn {
    font-size: 1.4rem;
  }
}
