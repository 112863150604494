.container {
  position: relative;
  width: 45rem;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 6rem;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: fade-in 1.5s ease;
}

.container h2 {
  font-size: 2.4rem;
  color: var(--text-color);
  font-weight: 500;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.text {
  font-size: 1.6rem;
  color: var(--text-color);
}
