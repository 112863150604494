.container {
  width: 100%;
  height: 8rem;
  display: flex;
  align-items: center;
  /* border-radius: 0.4rem; */
  overflow: hidden;
  cursor: pointer;
  position: relative;
  margin-top: 0.4rem;
}

.image-container {
  width: 8rem;
  height: 8rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.img {
  width: auto;
  height: 100%;
  transition: all 0.5s ease;
}

.img:hover {
  transform: scale(1.1);
}

.overlay {
  background-color: rgba(56, 56, 56, 0.37);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
}

.details {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-body {
  flex: 1;
  height: 100%;
  /* background-color: #794c4c; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem 0;
}

.chat-article__title {
  width: 100%;
  padding: 0rem 0 0 1rem;
  /* background-color: #f6f6f6; */
  /* background-color: var(--chat-color); */
  color: var(--text-color);
  font-size: 1.6rem;
  text-align: left;
  /* border-bottom: 0.4rem solid #fff; */
  font-weight: 400;
}

.chat-article__msg {
  width: 100%;
  text-align: left;
  font-size: 1.4rem;
  color: #777;
  align-self: flex-start;
  padding: 0rem 0 0 1rem;
  /* border-bottom: 0.1rem solid #dddddd; */
  flex: 1;
}

.chat-message {
  font-size: 1.4rem;
}

.chat-user {
  background-color: var(--background);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  color: var(--text-color);
  z-index: 10;
  transition: all 0.3s ease;
}

.chat-user__image {
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;
  border: 4px solid var(--chat-color);
}

.chat-user__static {
  padding: 0.2rem 1rem;
}

.unread-message {
  position: absolute;
  bottom: 0.4rem;
  right: 0.4rem;
  border-radius: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.8rem;
  height: 0.8rem;
  background-color: red;
  color: #fff;
  font-size: 1.4rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .chat-article__title {
    font-size: 1.4rem;
  }
  .chat-article__msg {
    font-size: 1.2rem;
  }
  .chat-user__static {
    font-size: 1.4rem;
  }
}

/* 1000 */
@media (max-width: 62.5em) {
  .container {
    height: 6rem;
  }
  .image-container {
    width: 6rem;
    height: 6rem;
  }
  .chat-article__title {
    font-size: 1.2rem;
  }
  .chat-article__msg {
    font-size: 1.2rem;
  }
  .chat-user__static {
    font-size: 1.4rem;
  }
}

/* 850 */
@media (max-width: 53.13em) {
  .chat-user__static {
    font-size: 1.2rem;
  }
}

/* 800 */
@media (max-width: 50em) {
  .image-container {
    display: none;
  }
}

/* 700 */
@media (max-width: 43.75em) {
  .container {
    height: 8rem;
  }
  .image-container {
    display: flex;
    height: 8rem;
    width: 8rem;
  }
}

/* 350 */
@media (max-width: 21.88em) {
  .container {
    height: 6rem;
  }
  .image-container {
    height: 6rem;
    width: 6rem;
  }
}
