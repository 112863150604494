.container {
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-color: antiquewhite; */
}

.left-fade {
  left: 0;
  top: 0;
  width: 2rem;
  height: 93%;
  position: absolute;
  z-index: 10;
  /* background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 49%,
    rgba(255, 255, 255, 0) 100%
  ); */
}

.right-fade {
  right: 0;
  top: 0;
  width: 2rem;
  height: 93%;
  position: absolute;
  z-index: 10;
  /* background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 49%,
    rgba(255, 255, 255, 1) 100%
  ); */
}

.promoted-articles__body {
  width: 100%;
  height: 25rem;
  height: auto;
  padding-bottom: 1rem;
  overflow: hidden;
  overflow-x: auto;
  /* position: relative; */
}

.promoted-articles__body::-webkit-scrollbar {
  display: block;
  left: 0;
  /* width: 0.2rem; */
  height: 1rem;
}

.promoted-articles__body::-webkit-scrollbar-track {
  background: rgba(175, 175, 175, 0.034);
}

.promoted-articles__body::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb);
  border-radius: 2rem;
  border: 0.2rem transparent rgba(175, 175, 175, 0);
}

.promoted-articles__overflow {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 2rem 2rem 0rem 2rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    width: 100%;
  }
}
