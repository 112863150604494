.container {
  width: 100%;
  height: 3.2rem;
  background-color: var(--background);
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  border-radius: 0.2rem;
}

.container h5 {
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
}

.container p {
  font-size: 1.4rem;
}

.checkboxes {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icon {
  font-size: 1.8rem;
}
