.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 0;
}

.inner-container {
  height: 100%;
  width: 100%;
  max-width: 103rem;
  display: flex;
  gap: 4rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inner-container h3 {
  font-size: 3.2rem;
  color: var(--text-color);
  text-transform: uppercase;
}

.inner-container h5 {
  font-size: 2rem;
  font-weight: 500;
  color: var(--text-color);
}

.body {
  width: 100%;
  display: flex;
  gap: 0.6rem;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.description {
  font-size: 1.6rem;
  color: var(--text-color);
  text-align: left;
  margin-bottom: 2rem;
}

.contact-info {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: stretch;
}

.contact-card {
  min-width: 33%;
  background-color: var(--white);
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 0.2rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 0.8rem solid var(--white);
  transition: all 0.3s ease;
}
.contact-card:hover {
  border-color: var(--accent-secondary-color);
}
.header {
  width: 100%;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  color: var(--main-color);
}
.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--background); */
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 0.4rem;
}
.contact-card__body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.value {
  font-size: 1.6rem;
  color: var(--text-color);
  text-decoration: none;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .inner-container {
    padding: 0 2rem;
  }
}

/* 800 */
@media (max-width: 50em) {
  .contact-info {
    flex-direction: column;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .inner-container {
    padding: 0 1rem;
  }
}
