.container {
  padding-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  /* margin-bottom: 10rem; */
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .container {
    gap: 0;
  }
}

/* 500 */
@media (max-width: 31.25em) {
  .container {
    padding-top: 1rem;
  }
}
