.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 0;
}

.inner-container {
  height: 100%;
  width: 100%;
  max-width: 103rem;
  display: flex;
  gap: 4rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: antiquewhite; */
}

.inner-container h3 {
  font-size: 3.2rem;
  color: var(--text-color);
  text-transform: uppercase;
}

.inner-container h5 {
  font-size: 2rem;
  font-weight: 500;
  color: var(--text-color);
}

.body {
  width: 100%;
  display: flex;
  gap: 0.6rem;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.counters {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  gap: 2rem;
  color: var(--text-color);
  background-color: var(--background);
  border-radius: 0.4rem;
}
.counter h4 {
  font-size: 2.4rem;
}
.counter p {
  font-size: 2.4rem !important;
}

.description {
  font-size: 1.6rem;
  color: var(--text-color);
  text-align: left;
  margin-bottom: 1rem;
}

.list {
  margin-top: 30px;
  margin-bottom: 30px;
}
.list-item {
  margin-bottom: 20px; /* Added space between list items */
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .inner-container {
    padding: 0 2rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .inner-container {
    padding: 0 1rem;
  }
}
