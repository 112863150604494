* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: block;
  left: 0;
  width: 1rem;
}

*::-webkit-scrollbar-track {
  background: rgba(175, 175, 175, 0.034);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--accent-secondary-color);
  border-radius: 2rem;
  border: 0.2rem transparent rgba(175, 175, 175, 0);
}

body {
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: 62.5%;
  overflow-y: scroll;
}

#root {
  min-height: 100vh;
  width: 100%;
}

/* MODAL */
.modal-overlay {
  position: fixed; /* Fixes the overlay to cover the entire screen */
  top: 0;
  left: 0;
  width: 100vw; /* Covers the full viewport width */
  height: 100vh; /* Covers the full viewport height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center; /* Centers the modal horizontally */
  align-items: center; /* Centers the modal vertically */
  z-index: 1000; /* Ensures the overlay is above other content */
}

.modal {
  background-color: #fff;
  padding: 40px; /* Increased padding for more space inside the modal */
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  position: relative;
  max-width: 600px; /* Makes the modal wider */
  width: 90%;
  font-size: 1.2rem; /* Increases the font size for better readability */
  line-height: 1.5; /* Adds more space between lines */
  animation: fade-in 0.5s ease-in;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2.4rem;
  font-weight: 500;
  color: #555;
  border: 0.2rem solid #f6f6f6;
  padding: 0 1rem;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: all 0.5s ease;
}
.modal-close:hover {
  background-color: #f6f6f6;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

:root {
  /* VISUAL HEIGHT */
  --vh: 100%;

  --error-msg: rgb(214, 27, 27);
  --error: rgba(223, 72, 72, 0.918);
  --message: rgba(135, 201, 105, 0.918);
  --checked: rgb(74, 152, 255);
  --focused: rgb(74, 152, 255);
  --main-image: rgb(248, 136, 24);
  --chat-color: rgb(36, 85, 131);
  --chat-color-light: rgb(114, 186, 253);
  --text1: #555;
  --text2: #555;
  --border: hsl(0, 0%, 63%);
  --border2: rgb(230, 230, 230);
  --divider: rgba(92, 77, 156, 0.616);
  --divider2: rgb(218, 218, 218);
  --red: red;
  --user-profile: #f0f0f0;
  --user-profile: #ecf0f1;
  /* --main-color: rgb(74, 152, 255); */
  --main-color: rgb(91, 77, 156);
  --alert-icon: rgb(230, 83, 46);
  --input-separator: #7997b3;
  --secondary2-color: #dae5e9;

  --logo: #ff2810;

  --inactive: #7997b3;
  --disabled: #f1f1f1;
  --checkbox-disabled: #d1d1d1;
  --off: #ff2810;
  --on: #b9f790b7;
  --promoted: rgb(255, 153, 51);
  --promoted: rgb(255, 79, 25);
  /* COLOR PALETTE */
  /* 1 */
  /* --main-color: #2c3e50;
  --main-color-hover: #21303f;
  --secondary-color: #ecf0f1;
  --accent-color: #e74c3c;
  --accent-secondary-color: #2c3e5080;
  --background: #ecf0f1;
  --text-color: #34495e; */

  /* 2 */
  /* --main-color: #d35400;
  --main-color-hover: #c75000;
  --secondary-color: #f39c12;
  --accent-color: #e67e22;
  --accent-secondary-color: #2c3e5080;
  --background: #fde3a7;
  --text-color: #2c3e50; */

  /* 3 */
  /* --main-color: #3498db;
  --main-color-hover: #21303f;
  --secondary-color: #1abc9c;
  --accent-color: #2ecc71;
  --accent-secondary-color: #2c3e5080;
  --background: #ecf0f1;
  --text-color: #34495e; */

  /* 4 */
  /* --main-color: #2c3e50;
  --main-color-hover: #2c3e50;
  --secondary-color: #bdc3c7;
  --accent-color: #e74c3c;
  --accent-secondary-color: #2c3e5080;
  --background: #ecf0f1;
  --text-color: #2c3e50; */

  /* 5 */
  /* --main-color: #e74c3c;
  --main-color-hover: #e74c3c;
  --secondary-color: #3498db;
  --accent-color: #f1c40f;
  --accent-secondary-color: #2c3e5080;
  --background: #ecf0f1;
  --text-color: #2c3e50; */

  /* 6 */
  /* --main-color: #27ae60;
  --main-color-hover: #27ae60;
  --secondary-color: #2ecc71;
  --accent-color: #f39c12;
  --accent-secondary-color: #2c3e5080;
  --background: #ecf0f1;
  --text-color: #2c3e50; */

  /* 7 */
  /* --main-color: #9b59b6;
  --main-color-hover: #9b59b6;
  --secondary-color: #e74c3c;
  --accent-color: #3498db;
  --accent-secondary-color: #2c3e5080;
  --background: #ecf0f1;
  --text-color: #2c3e50; */

  /* 8 */
  --main-color: #34495e;
  --main-color-hover: #293c4e;
  --secondary-color: #1abc9c;
  --accent-color: #3498db;
  --accent-color: #2e78aa;
  --accent-color-hover: #0d6856;
  --accent-secondary-color: #2c3e5080;
  --background: #ecf0f1;
  --soft-background: #f5f5f5;
  --text-color: #2c3e50;
  --unit-color: #7f93a7d5;
  /* --secondary-color: #3498db; */
  /* --accent-color: #1abc9c; */
  /* --accent-color: rgb(26, 188, 156); */

  /* 9 */
  /* --background: #ecf0f1;
  --text-color: #2c3e50;
  --main-color: #2a4d69;
  --main-color-hover: #1e344b;
  --secondary-color: #4b86b4;
  --accent-color: #63ace5;
  --accent-secondary-color: #98c1d9; */

  /* BUTONS */
  --button: rgb(74, 152, 255);
  --button-hover: rgb(49, 128, 231);
  --active: rgb(38, 167, 96);
  --active-hover: rgb(60, 124, 89);
  --pending: rgb(219, 167, 55);
  --pending-hover: rgb(145, 90, 40);
  --pause: rgb(219, 203, 55);
  --pause-hover: rgb(133, 131, 27);
  --deleted: rgb(138, 36, 36);
  --deleted-hover: rgb(119, 25, 25);
  --admin-panel-btn: rgba(235, 235, 235, 0.603);
  --admin-panel-btn-hover: rgba(226, 226, 226, 0.795);

  --report-pending: rgb(189, 181, 74);
  --report-blocked: rgb(95, 28, 19);
  --report-blocked-hover: rgb(155, 50, 36);
  --report-rejected: rgb(53, 99, 26);
  --report-rejected-hover: rgb(98, 167, 58);
  --alert: rgba(243, 235, 116, 0.596);

  --block-article: rgb(185, 60, 44);
  --unblock-article: rgb(90, 170, 44);
  --flag-article: rgb(105, 79, 23);

  --blocked: rgb(185, 60, 44);
  --paused: rgb(168, 170, 42);
  /* --pending: rgb(82, 192, 54); */

  --promoted-article: rgba(180, 126, 26, 0.664);
  --category-icon-bg: rgba(74, 152, 255, 0);
  --category-icon-border: rgba(143, 184, 238, 0.137);
  --active-category: rgb(132, 206, 235);
  --category-icon: rgb(132, 206, 235);
  /* --category-icon: rgba(180, 126, 26, 0.664); */
  --category-icon-active: rgb(74, 152, 255);
  --category-icon-hover: rgb(102, 181, 212);
  --scroll-thumb: rgba(107, 107, 107, 0.281);

  --new: rgba(248, 185, 67, 0.61);

  /* IMAGES */
  --images-bg: rgb(246, 246, 246);

  /* SLOVA */
  --white: #fff;
  --text1: #333;
  --text2: #555;
  --text3: #888;
  --text3: #696969;

  /* FONT SIZE */
  --h1: 2.8rem;
  --h2: 2.6rem;
  --h3: 2.4rem;
  --h4: 2.2rem;
  --h5: 2rem;
  --f18: 1.8rem;
  --f16: 1.6rem;
  --f14: 1.4rem;
  --f12: 1.2rem;
  --f10: 1rem;
}

/* MEDIA QUERIES */

/* 1100 */
@media (max-width: 68.75em) {
  .modal {
    padding: 1rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .modal {
    width: 95%;
  }
}
