.container {
  /* DODANO ZA GRID */
  grid-column: 1/4;
  /* DODANO ZA GRID */
  width: 100%;
  height: 9rem;
  padding: 0 4rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 0rem;
  list-style: none;
  background-color: #fff;
}

.single-category {
  height: 9rem;
  width: 100%;
  display: flex;
  gap: 0.4rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  /* border-top: 0.4rem solid #fff; */
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  font-size: 2.8rem;
  background-color: var(--category-icon-bg);
  color: var(--accent-secondary-color);
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.single-category:hover {
  /* transform: scale(110%); */
  color: var(--main-color-hover);
}

.label {
  font-size: 1.2rem;
  padding-bottom: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  z-index: 1;
}

.icon {
  z-index: 1;
}

.hide {
  display: 1;
}

.cube {
  position: absolute;
  clip-path: polygon(80% 100%, 90% 10%, 0% 20%, 0% 90%);
  width: 7rem;
  height: 5rem;
  top: 3rem;
  background-color: var(--soft-background);
  /* transform: rotate(60deg); */
  rotate: 60deg;
  z-index: 0;
  border-radius: 50%;
  transition: all 1s ease;
}

/* MEDIA QUERIES */
/* 1600 */
@media (max-width: 100em) {
  .container {
    grid-column: 1/3;
  }
}

/* 1100 */
@media (max-width: 68.75em) {
  .container {
    width: 100%;
    /* gap: 0.4rem; */
    padding: 0rem 2rem;
    height: 8rem;
  }

  .single-category {
    font-size: 2.4rem;
    height: 6rem;
    border-width: 0.4rem;
    /* height: 6.2rem;
    width: 10rem;
    width: 100%;
    border: 0.2rem solid var(--category-icon-border);
    border: 0.2rem solid var(--accent-secondary-color); */
  }
  .label {
    font-size: 1rem;
  }
  .cube {
    top: 0rem;
    height: 4rem;
    width: 5rem;
  }
}

/* 900 */
@media (max-width: 56.25em) {
  .container {
    padding: 0;
  }
}

/* 800px */
@media (max-width: 50em) {
  .hide {
    display: none;
  }
}

/* 700px */
@media (max-width: 43.75em) {
  .single-category {
    font-size: 2.4rem;
  }
  .label {
    font-size: 1rem;
    /* font-weight: 500; */
  }
}

/* 600px */
@media (max-width: 37.5em) {
  .container {
    display: none;
  }
}

/* 550px */
@media (max-width: 34.38em) {
  .container {
    padding: 0 1rem;
  }
}
