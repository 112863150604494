.container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff8e;
  backdrop-filter: blur(4px);
  z-index: 50;
  animation: 0.5s fadeIn ease;
}

.content {
  width: 100%;
  background-color: var(--background);
  border-top: 0.1rem solid var(--main-color);
  border-bottom: 0.1rem solid var(--main-color);
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border: 0.2rem solid var(--main-color); */
  /* border-radius: 1rem; */
  padding: 2rem 1rem 1rem 1rem;
}

.container h5 {
  font-size: 1.8rem;
  color: var(--text-color);
  font-weight: 400;
}

.btns {
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  gap: 1rem;
}

.btn {
  background-color: var(--main-color);
  color: #fff;
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 0.4rem;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.btn:hover {
  background-color: var(--main-color-hover);
}

.promotion-packages {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.single-package {
  padding: 0 1rem;
  border-radius: 0.2rem;
  height: 3rem;
  font-size: 1.4rem;
  background-color: var(--background);
  background-color: #fff;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-right: 0.2rem solid #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.single-package:hover {
  border-color: var(--main-color);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .content {
    width: 100%;
    padding: 1rem;
    margin: 0 1rem;
  }
  .container h5 {
    font-size: 1.6rem;
  }
  .btns {
    font-size: 1.4rem;
    font-weight: 400;
  }
}

/* 450 */
@media (max-width: 28.13em) {
  .container h5 {
    font-size: 1.4rem;
  }
  .btns {
    font-size: 1.2rem;
    flex-direction: column;
  }
  .btn {
    width: 100%;
  }
}
