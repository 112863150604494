.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #fff; */
  gap: 2rem;
}

.modal-phone {
  min-height: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-items: flex-start;
  justify-content: flex-end; */
  flex-direction: column;
  gap: 2rem;
  font-size: 1.6rem;
  color: var(--text-color);
}
.modal-phone h4 {
  font-size: 2rem;
  text-transform: uppercase;
  color: var(--text-color);
}

.btn {
  width: 100%;
  background-color: var(--button);
  padding: 0.8rem 0;
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  border-radius: 0.6rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.btn:hover {
  background-color: var(--button-hover);
}

.price {
  margin-top: 0rem;
  width: 104rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  /* border: 0.2rem solid var(--accent-secondary-color); */
  padding: 2rem 0;
  gap: 2rem;
  color: var(--text-color);
  border-radius: 1rem;
}
.price h5 {
  font-size: 2rem;
  text-transform: uppercase;
  color: var(--text-color);
  font-weight: 500;
}
.price p {
  font-size: 2rem;
  font-weight: 500;
  font-weight: 700;
}

.promoted {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  color: var(--main-color);
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  height: 4rem;
  width: 18rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--promoted);
  z-index: 10;
}

/* MEDIA QUERIES */
/* 1300 */
@media (max-width: 81.25em) {
}

/* 1100 */
@media (max-width: 68.75em) {
  .price {
    width: calc(100% - 4rem);
  }
}

/* 950 */
@media (max-width: 59.38em) {
  .price {
    margin-top: 0;
  }
  .price h5 {
    font-size: 1.8rem;
  }
  .price p {
    font-size: 1.8rem;
  }
}

/* 900 */
@media (max-width: 56.25em) {
}

/* 800 */
@media (max-width: 50em) {
  .price h5 {
    font-size: 1.6rem;
  }
  .price p {
    font-size: 1.6rem;
  }
  .price {
    gap: 1rem;
  }
}

/* 600 */
@media (max-width: 37.5em) {
  .container {
    gap: 1rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .price {
    width: calc(100% - 2rem);
  }
}
