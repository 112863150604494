.container {
  flex: 1;
  display: flex;
}

.description {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: pre-line;
}

.description h5 {
  font-size: 1.6rem;
  text-transform: uppercase;
  /* font-weight: 500; */
  color: var(--text-color);
  margin-bottom: 1rem;
  padding-left: 1rem;
  border-left: 0.4rem solid var(--main-color);
}

.description p {
  font-size: 1.6rem;
  text-align: left;
  color: var(--text-color);
}

/* MEDIA QUERIES */
/* 1300 */
@media (max-width: 81.25em) {
  .description h5 {
    font-size: 1.6rem;
  }
  .description p {
    font-size: 1.6rem;
  }
}

/* 900 */
@media (max-width: 56.25em) {
  .description h5 {
    font-weight: 500;
    padding-bottom: 0;
  }
}

/* 850 */
@media (max-width: 53.12em) {
}

/* 800 */
@media (max-width: 50em) {
  .description h5 {
    font-weight: 400;
  }
  .description p {
    font-size: 1.4rem;
  }
}

/* 650 */
@media (max-width: 40.63em) {
  .description h5 {
    font-size: 1.4rem;
  }
}
