/*  */
.images-upload__container {
  /* padding: 3rem; */
  /* padding: 0 2rem; */
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-template-rows: minmax(16rem, 18rem);
  row-gap: 2rem;
  column-gap: 3rem;
  z-index: 5;
}

.preview-img-container {
  margin: 0 auto;
  height: 18rem;
  width: 18rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.preview-img-container img {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  opacity: 0.3;
  transition: all 0.5s ease;
}

.preview-img-container img:hover {
  opacity: 1;
}

.img {
  height: 100%;
}

.preview-img-btns {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-img-btn {
  width: 50%;
  height: 4rem;
  background: none;
  border: none;
  background-color: rgba(231, 231, 231, 0.514);
  color: #00000057;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.preview-img-btn:hover {
  background-color: rgba(34, 34, 34, 0.726);
  color: #fff;
}

.icon {
  font-size: 2.4rem;
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .images-upload__container {
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    grid-template-rows: minmax(10rem, 10rem);
  }
  .preview-img-container {
    height: 10rem;
    width: 10rem;
  }
  .preview-img-btn {
    height: 3.2rem;
  }
  .icon {
    font-size: 1.6rem;
  }
}
