.container {
  max-width: 60%;
  min-width: 20rem;
  /* width: 60%; */
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1rem;
  padding: 1rem 1rem;
  z-index: 5;
  animation: pop-up 0.1s ease-in;
}

.me {
  align-self: flex-end;
  border-bottom-right-radius: 0;
  background-color: var(--background);
  color: var(--text-color);
}

.user {
  align-self: flex-start;
  border-bottom-left-radius: 0;
  background-color: var(--chat-color);
  background-color: var(--main-color);
  /* background-color: #2c5dff; */
  color: #fff;
}

.text {
  font-size: 1.6rem;
  word-break: break-all;
}

.details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.seen {
  font-size: 1.8rem;
}

.date {
  font-size: 1.2rem;
}

@keyframes pop-up {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* MEDIA QUERIES */
/* 1100 */
@media (max-width: 68.75em) {
  .text {
    font-size: 1.4rem;
  }
}

/* 1000 */
@media (max-width: 62.5em) {
}

/* 800 */
@media (max-width: 50em) {
  .container {
    padding: 1rem 0.6rem;
    min-width: 14rem;
  }
  .text {
    font-size: 1.2rem;
  }
  .date {
    font-size: 1rem;
  }
}
