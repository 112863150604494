.container {
  width: 104rem;
  background-color: #fff;
  padding: 2rem 2rem;
  border-radius: 1rem;
  position: relative;
}

.basic-info {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  /* justify-content: space-between; */
}

.single-info {
  display: flex;
  gap: 1.4rem;
  font-size: 2.4rem;
  color: var(--text-color);
  /* background-color: #f6f6f6; */
  border: 0.2rem solid #dddddd;
  padding: 0.4rem 2rem;
  border-radius: 0.6rem;
  /* border-radius: 10rem; */
}

.single-info p {
  font-size: 1.6rem;
}

.category {
  border-left: 0.4rem solid var(--main-color);
  margin: 2rem 0 2rem 0;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-color);
  text-transform: uppercase;
}

.category-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6rem;
}

.category h5 {
  font-size: 1.8rem;
}
.category p {
  font-size: 1.8rem;
}

.separator {
  font-size: 2.4rem;
}

.container h4 {
  font-size: 2.4rem;
  font-weight: 500;
  color: var(--text-color);
  margin-top: 0rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--background);
  padding: 2rem 0;
  border-radius: 1rem;
  position: relative;
}

/* .container::before {
  content: "PROMOTED";
  position: absolute;
  height: 2.4rem;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: var(--accent-color);
  right: -0.6rem;
  top: 5rem;
  font-size: 1.4rem;
  border-bottom-right-radius: 0.4rem;
  z-index: 10;
}
.container::after {
  content: "";
  position: absolute;
  background-color: var(--accent-color-hover);
  right: -0.6rem;
  top: 4.4rem;
  font-size: 0.4rem;
  border-top-right-radius: 0.4rem;
  height: 0.6rem;
  width: 0.6rem;
} */

.item-condition {
  margin-left: 2rem;
  font-size: 1.8rem;
  font-weight: 400;
  padding: 0.4rem 2rem;
  border: 0.2rem solid var(--accent-secondary-color);
  border-radius: 0.6rem;
  color: var(--text-color);
}

.body {
  margin-top: 2rem;
  width: 100%;
  /* height: 50rem; */
  display: flex;
  align-items: flex-start;
  gap: 4rem;
}

.article-id {
  cursor: pointer;
  transition: all 0.3s ease;
}
.article-id:hover {
  background-color: var(--background);
}

/* MEDIA QUERIES */
/* 1300 */
@media (max-width: 81.25em) {
  .single-info {
    font-size: 1.8rem;
  }
  .single-info p {
    font-size: 1.4rem;
  }
  .category h5 {
    font-size: 1.6rem;
  }
  .category p {
    font-size: 1.6rem;
  }
  .container h4 {
    font-size: 2rem;
  }
  .item-condition {
    font-size: 1.6rem;
  }
}

/* 1100 */
@media (max-width: 68.75em) {
  .container {
    width: calc(100% - 4rem);
    padding: 2rem 1rem;
  }
  .container h4 {
    padding: 2rem 1rem;
  }
}

/* 950 */
@media (max-width: 59.38em) {
  .body {
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0;
  }
  .container h4 {
    /* margin-top: 2rem; */
    /* margin-bottom: 3.2rem; */
  }
}

/* 900 */
@media (max-width: 56.25em) {
  .container h4 {
    font-weight: 500;
    /* margin: 1rem 0 2rem 0; */
    font-size: 1.8rem;
  }
  .category h5 {
    font-weight: 500;
  }
  .item-condition {
    font-size: 1.4rem;
  }
  .body {
    margin-top: 0;
  }
  .container {
    padding: 2rem 1rem 1rem 1rem;
  }
  .container::before {
    top: 20rem;
  }
  .container::after {
    top: 19.4rem;
  }
}

/* 800 */
@media (max-width: 50em) {
  .single-info {
    font-size: 1.6rem;
    padding: 0.6rem 1rem;
  }
  .single-info p {
    font-size: 1.2rem;
  }
  .category h5 {
    font-size: 1.4rem;
  }
  .category p {
    font-size: 1.4rem;
  }
  .container h4 {
    font-weight: 400;
    font-size: 1.6rem;
  }
}

/* 700 */
@media (max-width: 43.75em) {
  .item-condition {
    padding: 0.2rem 1rem;
    font-size: 1.2rem;
    align-self: center;
    margin-right: 1rem;
  }
  .container h4 {
    font-size: 1.6rem;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1rem 0;
  }
}

/* 650 */
@media (max-width: 40.63em) {
  .single-info {
    gap: 0.6rem;
    font-size: 1.4rem;
  }
  .single-info p {
    font-size: 1rem;
  }
}

/* 550 */
@media (max-width: 34.38em) {
  .container {
    width: calc(100% - 2rem);
  }
  .basic-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.4rem;
  }
  .single-info {
    gap: 1rem;
  }
  .single-info p {
    font-size: 1.2rem;
  }
  .container::before {
    top: 24rem;
  }
  .container::after {
    top: 23.4rem;
  }
  .category {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
  }
}

/* 500 */
@media (max-width: 31.25em) {
}

/* 450 */
@media (max-width: 28.13em) {
  .single-info {
    gap: 0.6rem;
  }
  .single-info p {
    font-size: 1rem;
  }
  .category {
    gap: 0.4rem;
  }
  .category h5 {
    font-size: 1.2rem;
  }
  .category p {
    font-size: 1.2rem;
  }
  .separator {
    font-size: 1.6rem;
  }
}

/* 380 */
@media (max-width: 23.75em) {
  .basic-info {
    grid-template-columns: 1fr;
  }
  .container h4 {
    font-size: 1.4rem;
  }
  .container::before {
    top: 30rem;
  }
  .container::after {
    top: 29.4rem;
  }
}
